@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.self-supply-setup-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;
  button {
    border: solid 2px $sun;
    text-transform: uppercase;
    padding: 10px;
    height: 60px;
    width: 10em;
    font-weight: $bold-text;
    border-radius: $radius-large;
    cursor: pointer;
  }
  .cancel {
    background: $white;
    color: $sun;
  }
  .go-to-slider {
    background: $sun;
    color: $white;
  }
  .back {
    background: $sun;
    color: $white;
  }
  .setup-mode-slider-container {
    width: 100%;
  }
  .setup-mode-actions-container {
    display: flex;
    flex-direction: row;
  }
}
@include touch {
  .self-supply-setup-page {
    width: 85%;
    margin: 0 auto;

    .setup-mode-actions-container {
      display: flex;
      flex-direction: column;
    }
    .setup-mode-slider-container {
      width: 100% !important; // override the column width on mobile
    }
  }
}
