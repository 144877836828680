@import '../../styles/variables';

.tabs-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  > div:first-child {
    flex-grow: 0;
  }
  > div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.tabs {
  overflow: hidden;

  .underline-separator {
    &::after {
      content: '';
      border-bottom: 1px solid rgba(131, 139, 152, 0.1);
      position: absolute;
      width: 66.5rem;
      top: 6.6em;
      overflow: hidden;
    }
  }
  a {
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-right: -0.2em;
    color: $gray-text-alt2;
  }
  li {
    &.is-active {
      a {
        font-weight: $bold-text;
        color: $black;
      }
      border-bottom: 2px solid $sun;
    }
    border-bottom: 2px solid transparent;
    margin-right: 2em;

    &:last-child {
      margin-right: 0;
    }
  }
}
