@import '../../styles/variables';

.widget-wrapper {
  display: flex;
  flex-direction: column;

  header {
    flex-grow: 0;
    border-bottom: 1px solid $gray-background;

    h3 {
      letter-spacing: 0.2em;
    }
  }

  > div {
    flex-grow: 1;
  }

  .widget-icon {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  .widget-content {
    min-height: 15em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
