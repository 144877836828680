@import '../../../styles/variables';
.panel-actions-container {
  display: flex;
  flex-direction: column;
  .layout-summary-container {
    display: flex;
    flex-direction: row;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .layout-totals-container {
      display: flex;
      flex: 2;
      box-shadow: 4px 4px 16px rgba(89, 112, 119, 0.15);
      border-radius: 5px;
      font-size: 13px;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      background-color: $white;
      @media (max-width: 767px) {
        margin-right: 10px;
      }
      .layout-timeframe {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 15px 0px;
        align-items: center;
        justify-content: center;
      }
      .divider {
        width: 1px;
        background-color: #ededed;
        height: 80%;
      }
      .summary-title {
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: center;
      }
      .summary-amount {
        text-transform: uppercase;
        font-weight: bold;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-align: center;

        &.total {
          text-transform: none;
        }
      }
    }
    .empty-container {
      display: flex;
      flex: 3;
    }
    .timelapse-container {
      display: flex;
      flex: 3;
      background-color: white;
      box-shadow: 4px 4px 16px rgba(89, 112, 119, 0.15);
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 7px;
      @media (max-width: 767px) {
        margin: 0;
      }
      .timelapse-content {
        display: flex;
        flex-direction: row;
        background: #cacaca20;
        align-content: center;
        justify-content: center;
        color: #404040;
        width: 100%;
        border-radius: 5px;
        padding: 20px 0px;
        cursor: pointer;
      }
      .timelapse-graph-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        min-height: 75px;
        .play-button {
          background-color: $sun;
          width: 46px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .graph-container {
          width: 100%;
          position: 'relative';
          margin-left: 3px;
          .graph-background {
            position: absolute;
            top: 0;
            right: 0;
            left: 47px;
            background-color: #c4c4c420;
            height: 46px;
            border-radius: 5px;
          }
        }
      }
    }
    .actions-web {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .actions-container {
      display: flex;
      flex-direction: row;
      box-shadow: 4px 4px 26px rgba(217, 224, 226, 0.5);
      border-radius: 5px;
      width: 141px;
      align-items: center;
      background-color: white;
      height: 100%;
      .zoom-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 50px;
        align-items: center;
        .zoom-in {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-top: 15px;
        }
        .divider {
          height: 1px;
          width: 100%;
          background-color: $gray-border;
        }
        .zoom-out {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-bottom: 15px;
        }
      }
      .pan-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        padding: 10px;
        border-left: 1px solid $gray-border;
        .action-instructions {
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          margin-top: 14px;
          text-align: center;
        }
      }
    }
  }

  .panels-legend-indicator {
    margin-top: 17px;
    width: 100%;
  }
}

.actions-upper-container {
  display: flex;
  flex: 2;
  flex-direction: row;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  .actions-mobile {
    @media (min-width: 767px) {
      display: none;
    }
  }
}
