@import '../../styles/variables';

.container {
  .header-container {
    display: flex;
    flex-direction: row;
    font-family: Open Sans;
    font-style: normal;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .labels-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $white;
      padding-left: 60px;
      flex: 1;
      @media (max-width: 767px) {
        padding: 0px;
        margin-top: 21px;
        margin-bottom: 32px;
        margin-left: 25px;
      }
      .title {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.08em;
        color: $lava50;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .subtitle {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.08em;
        color: $black;
        width: 430px;
        margin-top: 8px;
        @media (max-width: 767px) {
          margin-top: 0px;
          font-size: 17px;
          width: 297px;
        }
      }
    }
  }
  .content-container {
    background-color: $light-gray;
    padding: 67px 83px 50px 141px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0px;
      background-color: $white;
      align-items: flex-start;
    }
    .faqs-title {
      font-weight: bold;
      font-size: 17px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.08em;
      @media (max-width: 767px) {
        margin-left: 25px;
      }
    }
    .underline {
      width: 48px;
      height: 4px;
      background: $sun;
      margin-top: 10px;
      margin-bottom: 36px;
      @media (max-width: 767px) {
        margin-left: 25px;
      }
    }
  }
}
