@import '../../styles/variables';

.contact {
  &-content {
    padding: 1.25rem;
  }

  &-emailus-img {
    margin-left: 4rem;
  }

  &-link {
    color: $dark-blue;
    font-size: 15px;
    letter-spacing: 0.9px;
  }

  &-phone {
    font-size: 2rem;
  }

  &-text {
    font-size: 1.1rem;
  }

  &-subtext {
    color: $lava;
  }

  &-days-info-wrapper {
    display: flex;
    flex-direction: column;
    .days-subtitle {
      color: $lava50;
      margin-bottom: 10px;
    }
    .days-row {
      width: 200px;
      display: flex;
      .days-separator {
        flex: 1;
        overflow: hidden;
        &::after {
          content: '.....................';
          letter-spacing: 2.5px;
        }
      }
    }
  }

  &-hours {
    margin-bottom: 5rem;
  }

  &-separator {
    margin: 2rem 0;
  }

  &-column-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }
}
