@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.sites-list {
  .list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .sites-list-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30vh;
    padding-bottom: 30vh;
  }
}

@include mobile {
  .sites-list {
    .list-wrapper {
      flex-direction: column;
    }
  }
}
