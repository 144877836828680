@import '../../styles/variables';

.alert-modal-contents {
  height: 450px;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 60%;
    }
    .custom-icon {
      max-width: 135px;
      margin-bottom: 16px;
    }
    .custom-modal-button {
      min-width: 212px;
    }
    .custom-modal-footer {
      display: flex;
      margin-top: 46px;
      .custom-link {
        color: $blue;
        margin-left: 5px;
        font-weight: bold;
      }
    }
    .custom-modal-tittle {
      font-size: 16px;
    }
    .custom-modal-body {
      margin: 32px 0 41px;
      font-size: 15px;
    }
    .troubleshooting-guide {
      color: $gray-text-alt2;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      .link_to_faq {
        padding: 0 5px;
        color: $blue;
      }
    }
    .alert-email-message {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      color: $gray-text-alt2;
    }
    .support-email {
      display: flex;
      font-weight: bold;
    }
  }
  &.id,
  &.ice {
    .alert-email-message {
      justify-content: center;
    }
  }
}
.alert-modal-legal-document-content {
  height: 634px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    background-color: $gray-background2;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      background-color: $white;
    }
    .labels-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 60px;
      margin-top: 43px;
      @media (max-width: 767px) {
        margin-left: 16px;
        margin-top: 24px;
      }
      .title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
      }
      .subtitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.08em;
      }
      .review {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.05em;
      }
    }
    .animation-container {
      transform: scaleX(-1);
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
  }
  .content {
    display: flex;
    flex: 1;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    padding-top: 17px;
    padding-left: 57px;
    padding-right: 61px;
    margin-bottom: 90px;
    @media (max-width: 767px) {
      padding-left: 16px;
      padding-right: 16px;
      overflow: auto;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 19px;
    padding-right: 22px;
    padding-bottom: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -2px -2px 5px rgba(202, 220, 224, 0.33);
    background-color: white;
    .cancel {
      background-color: transparent;
      border: none;
      width: 120px;
      height: 46px;
      margin-right: 18px;
      color: $lava75;
      cursor: pointer;
    }
    .agree {
      padding: 10px;
      width: 196px;
      height: 46px;
      font-weight: bold;
      border-radius: $radius-large;
      background: $sun;
      color: $white;
      border: none;
      cursor: pointer;
    }
  }
}

.alert-modal-opted-out {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 28px 28px 95px 25px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  text-align: center;

  .header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .close-icon {
      filter: invert(0%) sepia(6%) saturate(100%) hue-rotate(309deg)
        brightness(0%) contrast(101%);
    }
  }
  .opt-out-title {
    font-weight: 700;
    display: flex;
    flex-direction: row;
    .opt-out-time {
      font-weight: normal;
      margin-left: 5px;
    }
  }
  .opt-out-sub-title {
    font-size: 16px;
    margin-top: 20px;
    color: $forest;
  }
  .opt-out-content {
    margin-top: 20px;
  }
  .opt-out-bold {
    font-weight: 700;
  }
  .buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    .cancel {
      background-color: transparent;
      border: solid 1px $sun;
      color: $sun;
      height: 46px;
      margin-right: 9px;
      cursor: pointer;
      border-radius: $radius-large;
      width: 142px;
    }
    .agree {
      width: 142px;
      height: 46px;
      font-weight: bold;
      border-radius: $radius-large;
      background: $sun;
      color: $white;
      border: none;
      cursor: pointer;
    }
    .end-chat-cancel-button {
      color: $lava;
      font-weight: bold;
      border-width: 2px;
    }
  }
}
