@import '../../styles/variables';

.payments {
  h3 {
    font-weight: bold;
    font-size: 2.08rem;
    margin-bottom: 2rem;
    max-width: 50rem;
    text-align: center;
  }

  &-text {
    color: $gray-subtitle;
    font-size: 1.25rem;

  }

  &-content {
    padding: 1rem 5rem 8.5rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &-button {
    text-transform: uppercase;
    border: none;
    margin-top: 5rem;
  }

  &-helpText {
    color: $sun-bullet;
    margin-top: 1.5rem;
  }
}
