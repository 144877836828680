@import '../../../styles/variables';
@import '~bulma/sass/utilities/initial-variables';

.legends-container {
  display: flex;
  justify-content: space-between;
  @media all and (max-width: $tablet) {
    margin-left: 0;
  }

  .legend {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
    }

    &-title {
      padding-top: 1rem;

      .bold {
        font-weight: bold;
      }
    }
  }
}

.legends-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .mark {
    border-bottom-width: 5px;
    border-bottom-style: solid;
    width: 1.5rem;

    &-home {
      border-color: $lava75;
    }

    &-solar {
      border-color: $sun;
    }

    &-grid {
      border-color: $blue;
    }

    &-storage {
      border-color: $forest;
    }
  }
  .legend {
    margin: 0 auto;
    &-title {
      padding-top: 0.5rem;
      display: block;

      &-home {
        color: $lava75;
      }

      &-solar {
        color: $sun;
      }

      &-grid {
        color: $blue;
      }

      &-storage {
        color: $forest;
      }
    }

    &-total {
      .bold {
        font-weight: lighter;
      }
    }
  }
}

.new-legends-header-container {
  display: flex;
  align-items: flex-end;

  &.consumption-group-container {
    display: grid;
    grid-template-areas: 'home-area electrical-grid-area solar-area';
    gap: 1rem;
    margin-left: 3rem;
    width: 95%;
  }
  &.storage-group-container {
    display: grid;
    grid-template-areas:
      'home-area electrical-grid-area'
      'solar-area storage-area';
    gap: 1rem;
    margin-left: 3rem;
    width: 95%;
  }
}
@media only screen and (max-width: 1000px) {
  .new-legends-header-container {
    align-self: center;
    justify-self: center;

    &.storage-group-container {
      display: grid;
      grid-template-areas:
        'home-area'
        'electrical-grid-area'
        'solar-area'
        'storage-area';
      gap: 1rem;
      width: 90%;
    }
    &.consumption-group-container {
      display: grid;
      grid-template-areas:
        'home-area'
        'electrical-grid-area'
        'solar-area';
      gap: 1rem;
      margin-left: 3rem;
      width: 95%;
    }
  }
}

.newlegend-container {
  display: flex;
  align-items: center;
  &.selectable {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.4;
  }
  .icon {
    margin-top: 3px;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  .newlegend-checkbox {
    margin-right: 10px;
  }
  .newlegend-title {
    font-size: 12px;
  }
  .home-title {
    color: $lava75;
  }
  .solar-title {
    color: $sun;
  }
  .grid-title {
    color: $blue;
  }
  .storage-title {
    color: $forest;
  }

  .newlegend-dots {
    flex: 1;
    border-bottom: 1px dashed $gray-text-alt2;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 3px;
  }
  .newlegend-total {
    font-size: 1.15rem;
    overflow: auto;
    line-height: 1.35rem;
    align-self: center;
    text-align: right;
    justify-self: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-total {
      .bold {
        font-weight: bold;
      }
    }
  }
}
