@import '~bulma/sass/utilities/_all.sass';
@import '../../styles/variables';

.modal-content {
  background-color: $white;
  border-radius: $radius-large;
}

.modal-card-head {
  button {
    background-color: $sun;

    &:hover,
    &:focus {
      background-color: lighten($sun, 20%);
    }
  }

  img.close {
    cursor: pointer;
  }
}

.modal-hide-border {
  border: none;
}

@include mobile {
  .modal-card-head {
    display: flex;
    flex-direction: row;
    h1 {
      order: 0;
      width: 90%;
    }
    img {
      order: 1;
      align-self: center;
    }
  }
}
