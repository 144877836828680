@import '../../styles/variables';

.overlay-modal {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 49px;
  background: rgba(255, 255, 255, 0.9);
  z-index: $z-index-sidebar;
  overflow-y: scroll;

  img {
    height: fit-content;
    float: right;
    cursor: pointer;
  }
}
