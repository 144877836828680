@import '../../../../styles/variables';
.wrapper-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 440px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 4rem;

  @media (max-width: $mobile-breakpoint) {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .top-information-container {
    display: flex;
    flex: 1;
  }

  .left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 50%;
    padding-right: 1vw;
    padding-left: 5vw;
    padding-bottom: 50px;
    @media (max-width: $mobile-breakpoint) {
      width: 90%;
    }
    .sunpower-logo {
      width: 164px;
      height: auto;
    }

    h1 {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      margin: 0;
      @media (max-width: $mobile-breakpoint) {
        font-size: 33px;
      }

      span {
        color: $forest;
      }
    }
    h2 {
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      line-height: 30px;
      letter-spacing: 0.08em;
      span {
        color: $lava50;
      }
    }
    .actions-container {
      h3 {
        display: inline-block;
        margin: 0;
        line-height: 40px;
      }
      button {
        width: 150px;
        margin-right: 20px;
      }
      @media (max-width: $mobile-breakpoint) {
        margin-top: 20px;
      }
    }
  }
  .footer-container {
    padding: 15px;
    padding-top: 0;
    color: $lava25;
    a {
      color: $lava25;
      text-decoration: underline;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 8px;
    }
  }
}
