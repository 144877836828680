@import '../../styles/variables';
.footer {
  background: #e6e6e6;
  position: relative;
  z-index: 23;
}
.main-logo-container {
  @media (max-width: $mobile-breakpoint) {
    padding: 0;
  }
}
.copyright-web {
  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
  margin-top: 1rem;
  letter-spacing: 0.15em;
  color: $gray-text;

  strong {
    color: $gray-text;
  }
}

.copyright-mobile {
  display: none;
  margin-top: 15px;
  @media (max-width: $mobile-breakpoint) {
    display: block;
  }
}

.footer-info {
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
  }
}
.footer-store-links {
  img {
    display: block;

    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: $mobile-breakpoint) {
    display: flex;
    margin-top: 35px;
    padding: 0;
    img {
      width: 120px;
      margin-right: 15px;
    }
  }
}

.footer-links {
  border-left: 4px solid $sun;
  padding-left: 1em;

  a {
    display: block;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
    letter-spacing: 0.15em;
  }
}
