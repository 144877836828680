@import '../../../../styles/variables';
.modal-wrapper {
  position: fixed;
  z-index: 25;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);

  .modal-content {
    background-color: $white;
    margin: 18% auto; /* 15% from the top and centered */
    padding: 20px;
    width: 500px;
    display: flex;
    position: relative;

    .main-button {
      margin: 20px 0;
    }

    .cta-modal-button {
      width: 100px;
      height: 35px;
      margin: 25px 0;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.39px;
      border-radius: 5px;
      color: $white;
      background-color: $sun;
      border: 0;
    }

    .close-button {
      background-color: transparent;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .house-icon {
      margin-top: 25px;
    }

    flex-direction: column;
    align-items: center;
    .modal-title {
      font-weight: bold;
      margin-top: 15px;
    }
    .modal-body {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
