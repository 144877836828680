@import '~bulma/sass/utilities/_all.sass';

.sites-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.space-around-justify {
  justify-content: space-around;
}
.space-between-justify {
  justify-content: space-between;
}

@include mobile {
  .sites-selection {
    flex-direction: column;
  }
}
