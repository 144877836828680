@import '../../../../styles/variables';

.season-overview .slider-container .slider {
  z-index: 25;
}

.season-overview .slider-container .backdrop {
  z-index: 24;
}

.season-overview {
  .slider-container {
  .slider {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 396px;
    animation: slide 0.35s forwards;
    background-color: $slider-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    @media (max-width: 767px) {
      width: 100%;
    }
    .toolbar {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      background-color: $white;
      box-shadow: 2px 2px 8px rgba(25, 27, 28, 0.11);
      .title {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.06em;
        margin-right: -50px;
      }
      .closeSideNav {
        padding: 5px;
        margin-right: -35px;
        background-color: $white;
        border: none;
        cursor: pointer;
        outline: none;
      }
      .arrow-left {
        margin-left: 10px;
        width: auto;
      }
    }

    .left-text {
      float: left;
    }

    .right-text {
      float: right;
    }

    .slider-cards-container {
      width: 100%;
      overflow: auto;
    }

    .subtitle-labels-container {
      width: 100%;
      padding: 20px 20px 40px 20px;
      font-weight: bold;
      color: $lava50;
    }

    .list-items-container{
      display: inline-block;
      width: 100%;
      height: 43px;
      color: $lava75;
      padding-top: 13px;

      .right-text {
        padding-right: 20px;
        font-weight: bold;
      }
      .left-text {
        padding-left: 20px;
      }
    }

    .list-items-container:nth-child(even)  {
      background-color: $lava10;
    }


  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0e0e0e;
    opacity: 0.5;
    animation: fadeIn 0.5s both;
  }
  .divider-container {
    height: 120px;
    width: 100%;
    .image-container {
      display: grid;
      position: relative;
      z-index: 10;
      top: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $slider-background;
      padding-bottom: 7px;
      width: 45px;
      svg {
        display: grid;
        width: 25px;
        align-self: center;
        justify-self: center;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      opacity: 0.5;
      border: 0.336296px solid $gray-separator;
      margin-bottom: 40px;
    }
    h4 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }
}
}
