@import "../../styles/variables";

.my-reports {
  &-empty {
    p {
      color: $gray-text-alt2;
      letter-spacing: 0.2em;
    }
  }
}
