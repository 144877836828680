@import '../../styles/variables';
@import "~bulma/sass/utilities/initial-variables";

.reserve-mode-page {
  display: flex;
  justify-content: space-between;

  .reserve-img {
    height: 185px;
  }

}
@media all and (max-width: $desktop)  {
  .reserve-mode-page {
    display: flex;
    flex-direction: column;
  }
}
