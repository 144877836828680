@import '../../../../styles/variables';
.enroll-step {
  padding: 26px 28px;
  border-top: 1px solid $lava10;
  .enroll-title-group {
    display: flex;
    justify-content: space-between;
  }
  .enroll-step-input-section {
    display: flex;
    justify-content: space-between;
    padding-left: 22px;
    margin-top: 22px;
  }
  .step-title {
    display: flex;
    align-items: center;
    .modal-icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .enroll-step-active {
    font-weight: bold;
  }
  .enroll-input-label {
    font-size: 10px;
    color: $lava50;
  }
  .enroll-input-text {
    height: 40px;
    width: 295px;
    border-width: 1px !important;
    border-radius: 5px;
    margin-top: 8px;
  }
  .enroll-step-next {
    height: 40px;
    width: 192px;
    margin-top: auto;
  }

  .step-docusign-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .enroll-step-docusign-button {
      border: solid $black;
      border-radius: 5px;
      width: 192px;
      height: 40px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
