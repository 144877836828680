@import '../../../styles/variables';

.vpp-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .vpp-list-title {
    color: $lava75;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .vpp-list-inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .vpp-list-empty-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;
    .stay-in-touch-img {
      margin-top: 24px;
    }

    .stay-touch-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: $lava;
    }
    .stay-touch-paragraph {
      color: $lava75;
      max-width: 300px;
      margin-top: 20px;
      a {
        color: $ocean;
        font-weight: bold;
      }
    }
  }
}

.vpp-list-loader {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: center;
  padding-top: 20%;
}

.vpp-list-footer {
  margin-top: 35px;
  color: $connected-help-text;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: $ocean;
    margin-top: 5px;
  }
}
