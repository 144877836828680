.panels-layout {
  margin-top: 160px;
  width: 100%;
  height: 100%;
  @media (max-width: 750px) {
    margin-top: 150px;
  }
  .konvajs-content {
    margin: 0;
  }
}
