@import '../../../../styles/variables';

.chat-card-container {
  display: flex;
  flex-direction: column;
  margin: 22px;
  .messages-container {
    width: 100%;
    display: flex;
    .message-data-container {
      .user-card {
        background-color: #f6f6f6;
        padding: 13px 16px;
        border-radius: 5px;
        max-width: 360px;
        min-width: 57px;
        margin-left: 15px;
        margin-right: 15px;
        text-align: left;
      }
      .non-user-card {
        background-color: #dcf1ff;
      }
      .message-data {
        display: flex;
        margin-right: 17px;
        margin-top: 5px;
        justify-content: flex-end;
        font-family: Open Sans;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.03em;
        .message-name {
          color: #b4b4b4;
        }
        .message-date {
          color: #808080;
          margin-left: 5px;
        }
      }
    }
  }
  .non-user-message-container {
    justify-content: flex-end;
  }
}
