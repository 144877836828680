@import '~bulma/sass/utilities/_all.sass';

.dashboard {
  .dashboard-header {
    min-height: 45em;
  }

  .dashboard-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30vh;
  }

  .graph-section-container {
    position: relative;
  }
}

@include touch {
  .dashboard {
    margin-top: 33px !important;
  }
}
