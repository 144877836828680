@import '../../styles/variables';

.calendar {
  &.right:before {
    right: 20%;
  }

  z-index: $z-index-max;
  position: absolute;

  .datetimepicker .datepicker-nav .datepicker-nav-month,
  .datetimepicker .datepicker-nav .datepicker-nav-year {
    color: $black;
  }

  .datetimepicker .datepicker-nav .datepicker-nav-previous,
  .datetimepicker .datepicker-nav .datepicker-nav-next {
    color: $black;
  }

  .datetimepicker.is-black .datepicker-body .datepicker-dates .datepicker-days .datepicker-date.datepicker-range .date-item.is-today {
    color: $black !important;
  }

  .date-item.is-today {
    color: $black !important;
  }

  .datepicker-nav {
    color: $black;
    background: transparent !important;
    button {
      padding: 0;
    }
  }
}
