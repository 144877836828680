@import "../../styles/variables";

.networks-list {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30em;

  ul {
    width: 100%;
    overflow: auto;
    max-height: 30em;

    li {
      cursor: pointer;
      &:hover {
        background: $gray-border;
      }
      &.selected {
        color: $white;
        background: $sun;
      }
      .current-network {
        display: flex;
        margin-right: 1em;
      }
    }
  }
}
