@import "../../styles/variables";

.status-label-wrapper {
  display: flex;

  &.spaced {
    letter-spacing: 0.25em;
  }

  .status-icon, .status-label {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .gray-text {
      color: $gray-text;
    }
  }

  .status-label-icon {
    cursor: pointer;
  }

  .status-text {
    display: flex;
    align-items: center;
  }
}
