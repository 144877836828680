@import '../../../../styles/variables';
@import '~bulma/sass/utilities/initial-variables';

.card-detail-column {
  margin-left: 50px;
  padding: 24px 40px 30px 40px;
  border-radius: 10px;
  margin-top: 75px;
  box-shadow: 4px 4px 24px rgba(132, 161, 172, 0.22);

  &.mobile {
    margin: 10px 0 0;
    padding: 15px;

    .card-header-container {
      display: flex;
      .selected {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      .info-container {
        flex: 1;
      }
    }
  }

  .card-detail-title {
    font-weight: 700;
    font-size: 14px;
  }
  .card-detail-subtitle {
    color: $lava50;
    font-size: 11px;
  }
  .off-peak-container {
    display: flex;
    align-items: center;
    .off-peak-square {
      height: 10px;
      width: 10px;
      border-radius: 2px;
      background-color: $off-peak;
    }
    .off-peak-label {
      font-size: 9px;
      color: $lava50;
      margin-left: 5px;
    }
  }

  .scheduled-times-title {
    margin-top: 40px;
    font-size: 13px;
  }
  .card-separator {
    height: 0.5px;
    width: 316px;
    background-color: $lava10;
  }
  .toggle {
    &-wrapper {
      height: 31px;
      margin: 20px 15px 0;
      border: solid black 1px;
      border-radius: 49px;
      display: flex;
    }
    &-option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      &.active-option {
        box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
        border-radius: 49px;
        width: 55%;
        &.inverted {
          box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.1);
        }
      }
      &.inactive-option {
        background-color: rgba(0, 0, 0, 0.01);
        cursor: pointer;
      }
    }
    &-icon {
      margin-right: 10px;
    }
  }
  .peak-table {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    border: 1px solid $lava10;
    border-radius: 10px;
    .table-header {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 8px;
      .times-square {
        height: 15px;
        width: 15px;
        border-radius: 2px;
      }
      .onPeak-square {
        background: $on-peak-times-gradient;
      }
      .partialPeak-square {
        background: $partial-peak-times-gradient;
      }
      .offPeak-square {
        background: $off-peak-times-gradient;
      }
      .criticalPeak-square {
        background: $critical-peak-times-gradient;
      }
      .table-header-label {
        font-size: 13px;
        margin-left: 10px;
      }
    }
    .table-separator {
      height: 1px;
      background-color: $lava10;
    }
    .table-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 30px 15px 30px;
      .peak-time-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-cell {
          font-size: 10px;
          color: $gray-separator;
        }
        .subtitle-cell {
          font-size: 19.5px;
        }
      }
      .table-arrow {
        height: 15px;
        width: 13px;
      }
    }
  }
}
