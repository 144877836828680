@import '../../../styles/variables';
.vpp-dashboard {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.vpp-dashboard-top-section {
  display: flex;
  flex-direction: column;
  height: 200px;
  background: $connected-header-active-deg;
  top: 0;

  .info-button-vpp {
    margin-top: 68px;
    margin-right: 3em;
    float: right;
  }

  .vpp-dashboard-content-card {
    position: relative;
    top: 15%;
    left: calc(50% - 375px);
    width: 750px;
    padding-bottom: 40px;
    .header-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 132px;
      padding-bottom: 20px;
      position: relative;
      .info-button {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
    }
    .view-documents {
      font-family: Open Sans;
      font-weight: 700;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
.vpp-dashboard-bottom-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $gray-background4;
}
.section-enroll {
  padding: 0;
}

.container-enroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.vpp-dashboard-loader {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  min-height: 1000px;
  justify-content: center;
  align-items: center;
  padding-top: 40%;
  z-index: auto;
  .vpp-dashboard-loader-info {
    margin-bottom: 15px;
  }
}
