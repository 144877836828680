.rate {
  &-star {
    cursor: pointer;
    padding: 0 .7rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
