@import '../../../styles/variables';
.vpp-dashboard-top-section {
  display: flex;
  flex-direction: column;
  height: 200px;
  background: $gray-background3;
}

.top-section-green-background {
  background: $connected-header-active-deg;
}

.section-enroll {
  padding: 0;
}

.container-enroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.vpp-documents-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .documents-content {
    margin-top: -60px;
    display: flex;
    width: 720px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 13px;
    box-shadow: 4px 4px 24px 0px rgba(132, 161, 172, 0.22);
    flex-direction: row;
    flex-direction: column;
    .pvs-il {
      margin-top: -75px;
      height: 160px;
      width: 100px;
    }
    .document-title {
      font-size: 15px;
      font-family: Open Sans;
    }
  }
}

.enrollment-document {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .document-separator {
    height: 1px;
    background-color: $lava10;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .document-name {
    font-size: 15px;
    font-family: Open Sans;
    font-weight: 700;
    margin-left: 25px;
  }
  .document-input {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 34px;
    border: 1px solid $lava;
    border-radius: 5px;
    min-width: 295px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
    .input-content {
      font-size: 14px;
    }
  }
  .document-signature {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 34px;
    border: 1px solid $lava;
    border-radius: 5px;
    width: 192px;
    height: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
