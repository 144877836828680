@import '../../../../styles/variables';

.case-modal-container {
  text-align: center;

  .case-modal-title {
    font-size: 15px;
    font-weight: 700;
    margin-top: 35px;
    letter-spacing: 0.45px;
  }

  textarea::placeholder {
    color: $lava25;
  }

  textarea::placeholder,
  .case-modal-subtitle,
  .message-box,
  .case-modal-btn-container {
    letter-spacing: 0.36px;
  }

  .case-modal-subtitle {
    color: $lava75;
    font-size: 12px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .message-box {
    width: 431px;
    height: 165px;
    font-family: 'Open Sans';
    border-radius: 5px;
    border: 1px solid $lava25;
    padding: 11px 18px;

    &:focus {
      outline: 1px solid $lava;
      box-shadow: 0 0 4px $lava;
    }
  }

  .case-modal-btn-container,
  .cancel-btn,
  .accept-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .case-modal-btn-container {
    margin-top: 30px;

    .cancel-btn,
    .accept-btn {
      width: 134px;
      height: 46px;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.39px;
      border-radius: 5px;
    }

    .cancel-btn {
      border: 2px solid $sun;
      margin-right: 15px;
      cursor: pointer;
    }

    .accept-btn {
      color: $white;
      background-color: $sun;
      cursor: pointer;
    }
  }
}
