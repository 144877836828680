@import '../../../../styles/variables';

.season-overview {
  position: relative;

  .state-content {
    padding: 20px 0;

    .power-without-earnings-label {
      margin-top: -10px;
      margin-right: -80px;
      text-align: center;
    }

    .power-discharge-label, .earnings-label {
      margin-top: -20px;
      text-align: center;
    }

    .value-label {
      font-size: 25px;
      font-weight: bold;
      margin-top: -5px;
    }

    .power-discharge-label {
      margin-right: -100px;
    }
    .power-earnings-img {
      width: 360px;
    }
    .earnings-label {
      margin-left: -75px;
    }

    .events-by-season-count {
      padding-left: 5px;
      font-weight: bold;
    }
  }

  .event-state-footer {
    width: 100%;
    padding: 20px 100px;
    border-top: 0.7px solid $gray-separator;
    display: block;

    .right-side-wrapper {
      float: right;
      display: flex;
      cursor: pointer;

      .arrow-past-events {
        padding: 1px 0 0 20px;
      }

      .total-events {
        color: $lava50;
      }
    }
  }
}
