@import '~bulma/sass/utilities/_all.sass';
@import '../../styles/variables';

.selectable-item {
  display: flex;
  background: #f2f5f7;
  border-radius: 4px;
  width: 49%;
  padding: 5px;
  min-height: 46px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  span {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    justify-content: center;
    padding: 5px;
    color: $lava;
    opacity: 0.5;
  }

  &.selected {
    background: $gray-text-alt2;

    span {
      color: $white;
      opacity: 1;
    }
  }
}

@include mobile {
  .selectable-item {
    width: 100%;
  }
}
