@import '../../styles/variables';
.top-banner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top-left-radius: $radius-large;
  border-top-right-radius: $radius-large;
  text-align: center;
  padding: 10px 0px 10px 0px;
  font-size: 10px;
  cursor: pointer;
  .bold-title {
    font-weight: 700;
    margin-right: 5px;
  }
  .arrow-icon {
    margin-left: 11px;
  }
}
.inactive {
  background: linear-gradient(
    90deg,
    $c-s-current-event1 1.31%,
    $c-s-current-event2 100%
  );
  color: $lava75;
}
.active {
  color: $white;
  background: linear-gradient(
    90deg,
    $forest 1.31%,
    $c-s-non-current-event2 100%
  );
}

.icon-active {
  filter: invert(93%) sepia(22%) saturate(464%) hue-rotate(208deg)
    brightness(108%) contrast(106%);
}
