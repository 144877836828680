@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.site-card {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 300px;
  background: url('./assets/card-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  cursor: pointer;

  .site-card-overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 100%;
    width: 100%;
    background: $dark-green-overlay;

    &:hover {
      background: rgba($color: $dark-green-overlay, $alpha: 0.5);
    }
  }

  .bottom-border {
    width: 50px;
    border-bottom: 1px solid $sun;
  }
}

@include mobile {
  .site-card {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
