.slider-card {
  display: grid;
  background: #FFFFFF;
  box-shadow: 4px 4px 24px rgba(132, 161, 172, 0.22);
  border-radius: 13px;
  width: 330px;
  height: 140px;

  header {
    margin: 1rem;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    h3 {
      font-weight: 700;
      font-size: 1rem;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: #000000;
      width: 140px;
    }
    .header-side-title-container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1.5rem;
      justify-self: right;
      align-self: right;
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #404040;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    border-top: 1px dashed #BFBFBF;
  }
  .slider-card-body {
    margin: 1rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #808080;
  }
}