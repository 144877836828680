@import '../../styles/variables';

.main {
  flex-grow: 1;
  background-color: $white-background;
}

.has-header {
  margin-top: 33px !important;
}

.switch-to-sales-header {
  position: fixed;
  padding: 7px 17px !important;
  width: 100% !important;
  z-index: 35 !important;
}
