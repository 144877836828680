@import '~bulma/sass/utilities/_all.sass';
@import '../../styles/variables';

.admin-page {
  .selection-container {
    background-color: white;
    box-shadow: 4px 4px 16px #dee9ee;
    border-radius: 14px;
    padding: 2rem;
    min-height: 180px;
    .tabs {
      ul {
        li {
          margin: 0 4rem;
        }
      }
    }
    .rows {
      display: flex;
      flex-direction: column;

      input {
        font-size: 1.2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background-color: #f2f5f6;
      }
      button {
        font-size: 1.05rem;
        padding: 1.8em 4em;
        font-weight: $bold-text;
        width: 100%;
        line-height: 0;
      }

      h3 {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.05em;
      }

      .selection-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        padding: 5px 90px;
      }

      .has-top-border {
        opacity: 0.2;
        border-top: 1px solid $gray-text-alt2;
      }
    }
  }
}

@include mobile {
  .admin-page {
    .selection-container {
      .selection-list {
        flex-direction: column;
        padding: 0 !important;
      }
    }
  }
}
