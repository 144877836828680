@import "../../styles/variables";

.wifi-settings {
  button {
    padding: 1.8em 3em;
    font-weight: $bold-text;
  }

  .wifi-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30em;
  }
}
