@import '../../styles/variables';

.battery-icon {
  position: relative;
  display: flex;
  justify-content: center;
  width: 250px;
  color: $white;
  z-index: 1;

  .background-circle {
    position: absolute;
    z-index: 1;
    margin-top: 4em;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background-color: $gray-background;
  }

  .battery-svg {
    position: relative;
    z-index: 2;
  }

  .energy-info {
    width: 100%;
    flex-direction: column;
    text-align: center;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 120px;
    h2 {
      color: inherit;
      font-weight: $regular-text;
      text-align: center;
      letter-spacing: -0.03em;
      margin-bottom: 0;

      justify-content: center;
      align-items: flex-start;

      .symbol {
        opacity: 0.5;
        margin: auto;
      }
    }
    span {
      letter-spacing: 0.15em;
      text-align: center;
    }
  }
}
