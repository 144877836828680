@import '../../styles/variables';

.tooltip-container {
  width: 350px;
  position: relative;
  bottom: 200px;

  background: $white;
  box-shadow: 4px 4px 16px $gray-border2;
  border-radius: $tooltip-radius;

  header {
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-size: 1.25rem;
    border-bottom: solid 1px $gray-border2;
  }
  .tooltip-items-container {
    padding: 0.5rem;

    .tooltip-item {
      margin: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: space-between;

      .arrow {
        width: 1rem;
        height: 1rem;
      }
      .arrow.left {
        transform: rotate(180deg);
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
      .title {
        flex-grow: 1;
        margin: 0;
        font-weight: normal;
        font-size: 1.25rem;
        letter-spacing: 2px;
        align-self: center;
        justify-self: center;
      }
      .value {
        font-weight: $bold-text;
      }
    }
  }
}
