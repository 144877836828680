@import './variables';
@import '~bulma/sass/utilities/initial-variables';

.setup-mode {
  &-column {
    width: 31rem;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-step {
    font-size: $font-size-bg;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $gray-text-alt2;
    b {
      color: black;
    }
  }

  &-days-setter {
    min-height: 18rem;

    .right-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &-btn-rounded {
    color: $sun;
    border: none;
    background-color: $white;
    font-size: 3rem;
    font-weight: bold;
    font-family: monospace;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    &:disabled img {
      opacity: 0.5;
    }
    span {
      position: absolute;
      &.minus {
        bottom: -0.2rem;
      }
    }

    &:focus {
      outline: 0;
    }

    &.float-right {
      float: right;
    }
  }

  &-title {
    font-size: $font-size-bg;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $black;
  }

  &-value {
    font-size: 10em;
    text-align: center;
    letter-spacing: -0.07em;
    font-weight: 100;

    .percentage {
      font-size: 5rem;
      text-align: center;
      letter-spacing: -0.03em;
      color: $sun;
    }
  }

  &-saved-reserves {
    font-size: 1rem;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    color: $gray-text-alt2;
    position: relative;
    bottom: 30px;
  }

  &-days {
    font-size: 1rem;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    color: black;
    position: relative;
    bottom: 30px;

    &.legend {
      color: $gray-text-alt2;
    }
  }

  &-options-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 1rem;
    align-items: center;
    min-height: 12rem;
  }

  &-actions-container {
    color: $sun;
    display: flex;
    margin: 0 1rem;

    .button {
      border: solid 2px $sun;
      text-transform: uppercase;
      padding: 0.83rem;
      height: 4rem;
      flex: 1;
      font-weight: $bold-text;
    }
    .btn-cancel {
      background: $white;
      color: $sun;
    }

    .btn-start {
      background: $sun;
      color: $white;
    }
  }
  &-btn-big {
    position: relative;
    width: 100%;
    background-color: $white;
    border: solid 2px $gray-border2;
    border-radius: $radius-large;

    font-size: $font-size;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.03em;
    padding: 1.2rem 0;

    &.selected {
      border: solid 2px $black;
    }

    .setup-mode-check-img {
      position: absolute;
      top: 1.3rem;
      left: 1rem;
    }
  }
}

.setup-mode-page {
  .actions-container {
    color: $sun;

    .button {
      border: solid 2px $sun;
      text-transform: uppercase;
      padding: 10px;
      height: 60px;
      width: 200px;
      font-weight: $bold-text;
    }
    .cancel {
      background: $white;
      color: $sun;
    }
    .go-to-slider {
      background: $sun;
      color: $white;
    }
    .back {
      background: $sun;
      color: $white;
    }
  }

  .setup-mode-details {
    h4 {
      color: $black;
      font-size: 2em;
      line-height: 33px;
      letter-spacing: 0.03em;
      font-weight: $bold-text;
    }
    h5 {
      font-size: 1.4em;
      line-height: 23px;
      letter-spacing: 0.1em;
      font-weight: normal;
    }
    h6 {
      font-size: 0.875em;
      line-height: 19px;
      letter-spacing: 0.1em;
      color: $gray-text-alt2;
    }
  }
}
