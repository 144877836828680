@import '../../../../styles/variables';

.layout-wrapper {
  background-color: $slider-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .simple-navbar {
    position: fixed;
    width: 100%;
    background-color: $lava;
    z-index: 20;
    height: 4rem;
    align-items: center;
    .navbar-title {
      color: $white-background;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 39px;
    }
    .navbar-back {
      position: absolute;
      left: 0;
      margin-left: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px;
    }
    .navbar-end-component {
      position: absolute;
      right: 0;
      margin-right: 20px;
    }
  }
  .layout-wrapper-loader {
    margin-top: 120px;
    img {
      width: 4em;
      height: 4em;
    }
  }
}
