@import '../../styles/variables';

.message {
  font-family: Open Sans;
  font-style: normal;
  margin: -8px;
  .message-header {
    display: flex;
    background-color: $white;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none !important;
    border-radius: 6px;
    padding: 24px 16px 24px 30px;
    .question-text {
      font-weight: 300;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.02em;
      flex: 1;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .chevron-container {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      background-color: $accordion-accent;
      display: flex;
      align-items: center;
      justify-content: center;
      .question-chev {
        transform: rotate(0deg);
        transition-duration: 0.2s;
        width: 17px;
        height: 7px;
      }
    }
    &.is-active {
      background-color: transparent;
      .question-chev {
        transform: rotate(180deg);
        transition-duration: 0.2s;
        width: 17px;
        height: 7px;
      }
    }
  }
  .message-body-content {
    background-color: $white;
    padding: 1em 1em 4em 1.5em;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 12px;
    }
    .color-text-blue {
      color: $blue !important;
    }
    article {
      margin: 2em 0;
    }
    ul {
      list-style: initial;
      margin-left: 2em;
    }
    ol {
      list-style-position: outside;
      width: 90%;
      margin-left: 2em;
      li {
        margin-left: 2.2em;

        ul {
          list-style: initial;
          list-style-position: outside;
          margin-left: 2em;

          li {
            padding-left: 1em;
          }
        }
      }
    }
  }
}
