@import '../../../styles/variables';
.enroll-top-section {
  height: 250px;
  background-color: $gray-background3;
  width: 100%;
  position: absolute;
  button {
    background-color: $sun;
    border-width: 0px;
    text-transform: none;
    padding: 10px;
    color: $white;
    font-weight: $bold-text;
    border-radius: $radius-large;
    cursor: pointer;
    box-shadow: none;
    width: 120px;
  }
  button:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
  .enroll-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 75%;
    .redirect-submit-button {
      align-self: center;
      margin-top: 20px;
    }
    .enroll-content-card {
      background-color: $white;
      width: 750px;
      border-radius: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding-bottom: 40px;
      .header-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 132px;
        padding-bottom: 20px;
        position: relative;
        .info-button {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
.enroll-bottom-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $gray-background4;
}
.section-enroll {
  padding: 0;
}

.container-enroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.vpp-enroll-loader {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  .vpp-enroll-loader-info {
    margin-bottom: 15px;
  }
}

.vpp-enroll-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  .vpp-enroll-finish-info {
    max-width: 266px;
    text-align: center;
  }
  .vpp-enroll-buttons-container {
    display: flex;
    flex-direction: column;
    .vpp-enroll-finish-button {
      width: 255px;
      &.edit {
        border: 1px solid $sun;
        background-color: transparent;
        color: $lava;
        margin-top: 80px;
        margin-bottom: 32px;
      }
    }
  }
}
