@import "../../styles/variables";

.more-button {
  button {
    text-transform: uppercase;
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }
  }

  .chevron {
      content: '';
      height: 10px;
      width: 10px;
      position: relative;
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: $sun;

      &.down {
        top: -2px;
        transform: rotate(135deg);
      }

      &.up {
        top: 3px;
        transform: rotate(-45deg);
      }
  }

  .more-content {
    border-top: 1px solid $gray-background;
    border-bottom: 1px solid $gray-background;
  }
}
