@import '../../styles/variables';

.menu-wrapper {
  z-index: 1;
  position: absolute;
  right: 35px;
  top: 5px;
}

.arrow-up {
  width: 0;
  height: 0;
  margin-left: 128px;
  margin-bottom: -47px;
  padding-top: 40px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 7px solid $white;
  filter: drop-shadow(2px 4px 4px $lava75);
  cursor: pointer;
}

.options-menu-popup {
  background-color: $white;
  height: fit-content;
  border-radius: 14px;
  position: absolute;
  white-space: nowrap;
  margin-top: 46px;
  cursor: pointer;

  hr {
    margin: 0;
  }

  &.box {
    padding: 0;
    box-shadow: 1px 5px 7px $gray-border;
  }

  .option-container {
    margin: 15px 10px;
  }
}
