@import '../../styles/variables';
@import "~bulma/sass/utilities/initial-variables";


.life-events {
  &-content {
    padding: 1.25rem;
    align-items: center;
  }

  .columns {
    padding: 0 2rem;
    @media all and (max-width: $tablet) {
      padding: 0;
    }
  }

  .tile {
    padding: 10px 10px;
    margin: 10px 10px;
    flex-direction: column;
  }
  &-img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 5px 5px 0 0;
    min-height: 200px;
  }
  &-color-1 {
    background-color: #EAF4F8;
  }
  &-color-2 {
    background-color: #FEF7EC;
  }
  &-color-3 {
    background-color: #F6FAF4;
  }
  &-description {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 80%;
    color: $black;
    font-size: 1.2rem;
    max-width: 21.5rem;
  }
  &-mark {
    border-bottom-width: 0.3rem;
    border-bottom-style: solid;
    width: 2rem;
    flex: 1;
    &-color-1 {
      border-color: #1771b9;
    }
    &-color-2 {
      border-color: #ef8b22;
    }
    &-color-3 {
      border-color: #6aa543;
    }
  }
}
