@import '../../../styles/variables';

.container-enroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .enroll-content-card {
    background-color: $white;
    position: relative;
    top: 75%;
    left: calc(50% - 375px);
    width: 750px;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding-bottom: 40px;
    .header-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: auto;
      padding-bottom: 20px;
      padding-top: 80px;
      position: relative;

      .enroll-image {
        position: absolute;
        top: -150px;
      }

      .accepted-image {
        position: block;
      }

      .enroll-status {
        font-size: 15px;
        &.pending-status {
          margin-top: 30px;
        }
      }

      .enroll-info-text {
        margin-top: 20px;
        width: 300px;
        text-align: left;
        color: $lava75;
      }

      .enroll-info-date-submitted {
        font-size: 9px;
        font-weight: bold;
        margin-top: 20px;
        color: $lava75;
      }
    }
    .accepted-card {
      min-height: 375px;
      .header-section {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.rejected-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .rejected-pvs {
    margin-top: -80px;
  }
  .rejected-title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px 25px;
    text-align: left;
  }
  .rejected-body {
    max-width: 270px;
    color: $lava75;
    text-align: left;
    font-size: 12px;
    width: 100%;
  }
  .resubmit-button {
    padding: 10px;
    width: 270px;
    height: 46px;
    font-weight: bold;
    border-radius: $radius-large;
    background: $sun;
    color: $white;
    border: none;
    cursor: pointer;
    margin-top: 32px;
  }
}
