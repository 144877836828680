@import '../../../../styles/variables';

.cases-slider-wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 18 !important;
  .cases-slider {
    position: fixed;
    top: 50px;
    right: 0;
    height: 100%;
    width: 396px;
    animation: slide 0.35s forwards;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    &.hasAdminBaner {
      padding-top: 20px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .toogle-container {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    .toogle {
      width: 201px;
      background-color: $lava10;
      color: $lava50;
      padding: 3px;
      border-radius: 15px;
      display: flex;
      cursor: pointer;
      animation: slide 0.35s forwards;
      &.right {
        justify-content: flex-end;
      }
      .button-toogle {
        height: 26px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        font-weight: bold;
        &.active {
          color: $sun-bullet;
          background: $white;
        }
      }
    }
  }
  .case-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    max-height: calc(
      100vh - 325px
    ); /* 325px = 50px (header) + 25px (toogle) + 25px (margins) + 200px (footer) */
    padding: 15px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .empty-container {
      text-align: center;
      font-weight: 700;
    }
  }
}
