@import '../../styles/variables';

.tabs-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  > div:first-child {
    flex-grow: 0;
  }
  > div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  text-align: center;
  .underline-separator {
    &::after {
      content: '';
      border-bottom: 1px solid rgba(131, 139, 152, 0.1);
      position: absolute;
      width: 66.5rem;
      top: 6.6em;
      overflow: hidden;
    }
  }
  span {
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-right: -0.2em;
    color: $gray-text-alt2;
  }
  article {
    display: grid;
    span {
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      width: fit-content;
      margin: 0 auto;
      border-bottom: 2px solid transparent;
    }
    &.is-active {
      span {
        font-weight: $bold-text;
        color: $black;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        border-bottom: 2px solid $sun;
        width: fit-content;
        margin: 0 auto;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
