@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.banner-container {
  position: fixed;
  width: 100%;
  top: $navbar-height;
  z-index: $z-index-sidebar;

  .alert {
    margin-bottom: 0.5rem;
    background-color: $white;
    min-height: 49px;
    box-shadow: 2px 2px 8px rgba(200, 208, 211, 0.7);
    align-items: center;
    justify-content: flex-start;

    img {
      margin: auto 12px;

      &.close-icn {
        cursor: pointer;
      }
    }
    span {
      color: $lava;
      font-size: 14px;
      letter-spacing: 0.04em;
    }
    .learn-more-modal {
      cursor: pointer;
      color: #0071b9;
      margin-left: 10px;
      flex-grow: 1;
    }
    &.custom-alert {
      margin-bottom: 0.2rem;
      .alert-icon {
        margin: auto 20px;
      }
    }
  }
}

.double-spacing {
  margin-top: 70px;
}

.single-spacing {
  margin-top: 35px;
}
