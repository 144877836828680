@import '../../styles/variables';

.filter-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  .filter {
    position: relative;
    cursor: pointer;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-width: 2px 1px;
    border-style: solid;
    border-color: $gray-border2;
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    &:first-child {
      border-width: 2px 1px 2px 2px;
    }

    &:last-child {
      border-width: 2px 2px 2px 1px;
    }

    &:hover:not(:last-child),
    &.selected {
      background: $black;
      color: $white;
      border-color: $black;

      .calendar {
        color: $black;
      }
    }

    .with-icon {
      margin-left: 2em;
    }

    &.switcher {
      width: 15%;
      padding: 0;

      div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-shrink: 1;
        justify-content: center;
        align-items: center;

        &:hover:not(.disabled) {
          background: $black;
          color: $white;
          border-color: $black;
        }

        &.disabled {
          opacity: 0.6;
          cursor: initial;
        }
      }

      span {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  .filter-calendar {
    top: 110%;
    width: 200%;
    left: 0;
  }
}
