@import '../../../styles/variables';

.day-picker {
  background-color: $white;
  padding: 1rem;
  z-index: 20;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  .day-selection-container {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: repeat(5, auto);
    align-self: center;
    justify-self: center;

    h4 {
      line-height: 17px;
      letter-spacing: 0.1em;
    }
    .day {
      display: grid;
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      align-items: center;
      line-height: 18px;
      letter-spacing: 0.01em;
    }
    .day:hover {
      background-color: $white;
      color: $sun;
      border: 1px solid $sun;
      border-radius: 50%;
    }
    .active {
      border-radius: 50%;
      background-color: $sun;
      color: $white;
    }
    .active:hover {
      background-color: $sun;
      color: $white;
    }
  }
}
