@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.slider-with-indicators-container {
  width: 100%;

  .slider-details {
    display: flex;

    .spwr-slider {
      max-width: 400px;
    }
    .right-indicator {
      text-align: left;
      max-width: 30px;
    }
    .left-indicator {
      text-align: right;
      max-width: 30px;
    }
    input[type='range'] {
      -webkit-appearance: none;

      width: 100%;
    }
    input[type='range']:focus {
      outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
      width: 100%;
      max-height: 4px;
      cursor: pointer;
      background: $black;
      border-radius: 25px;
    }
    input[type='range']::-webkit-slider-thumb {
      height: 30px !important; // needed or else overriden by bulma or something else and becomes off.
      width: 30px !important;
      border-radius: 50%;
      border: solid 2px $white;
      background: $sun;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 1);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -14px !important; // needed or else overriden by bulma or something else and becomes off.
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
      background: $black;
    }
  }
  .slider-subtitles {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 0 auto;

    .slider-indicator {
      font-size: 0.875em;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.05em;
      color: $gray-text-alt2;
      opacity: 0.6;
    }
  }
}

@include touch {
  .slider-with-indicators-container {
    .slider-details {
      display: flex;
      width: 100%;

      .spwr-slider {
        min-width: 250px !important;
      }
    }

    .slider-subtitles {
      width: 90% !important; // override the column width on mobile
    }
  }
}

.range-wrap {
  position: relative;
  width: 268px;
}
