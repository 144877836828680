@import '../../styles/variables';

.system-info {
  display: flex;
  flex-direction: column;
  height: 100%;

  .info-top {
    flex-grow: 1;
  }

  .info-label {
    font-weight: $bold-text;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .is-green {
    color: $green;
    font-size: $font-size-bg !important;
    line-height: 24px;
  }
  .is-canyon {
    color: $canyon;
    font-size: $font-size !important;
    line-height: 19px;
  }
  .info-image {
    img {
      width: 100%;
      height: 72px;
    }
  }
  .info-description {
    color: $gray-text-alt2;
    font-size: $font-size !important;
    line-height: 22px;
    letter-spacing: 0.125em;
    p {
      text-align: center;
      padding: 0 5%;
      .link-to-faq {
        color: $blue;
      }
    }
  }
}

.system-info-detail {
  .left-col {
    flex-grow: 0 !important;
    width: 10em;
  }
  .right-col {
    font-weight: $bold-text;
  }
}
