@import '../../../../styles/variables';
.support-case-card {
  border-radius: 15px;
  width: 340px;
  margin-bottom: 20px;
  box-shadow: $connected-card-shadow;
  .support-case-card-status {
    height: 36px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .support-case-card-id {
    height: 64px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding-left: 19px;
    padding-right: 19px;
    border-bottom: 1px solid $support-card-border;
    justify-content: space-between;
    .support-card-reopen {
      color: $ocean;
    }
  }
  .support-case-card-info {
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 20px;
    border-bottom: 1px solid $support-card-border;
    .card-info-data {
      color: #808080;
      margin-bottom: 15px;
    }
    .card-info-title {
      padding-bottom: 15px;
      font-weight: 700;
    }
  }
  .support-case-card-created {
    padding-left: 19px;
    padding-right: 19px;
    display: flex;
    border-bottom: 1px solid $support-card-border;
    height: 48px;
    align-items: center;
    justify-content: center;
    &-date {
      color: #808080;
      margin-left: 5px;
    }
  }
  .support-case-card-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $ocean;
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      cursor: pointer;
    }
    &-chevron {
      margin-left: 5px;
      &.closed {
        transform: rotate(180deg);
      }
    }
    &-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;

      .no-comments {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $lava75;
        text-align: center;
        border-bottom: 1px solid $support-card-border;
        border-top: 1px solid $support-card-border;
        padding: 25px 0 20px;
        p {
          max-width: 240px;
        }
      }
      .comment-item {
        display: flex;
        flex: 1;
        padding: 15px;

        &.sunpower {
          flex-direction: row-reverse;
        }
        .avatar-container {
          display: flex;
          justify-content: center;
          .avatar {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background-color: $gray-avatar;
            margin-right: 10px;
            margin-left: 10px;
            color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 600;
            &.sunpower {
              color: $white;
              background-color: $sun-avatar;
            }
          }
        }
        .chat-content {
          display: flex;
          flex-direction: column;
          color: $lava;
          flex: 1;
          &-user {
            display: flex;
            flex-direction: row-reverse;
            &.sunpower {
              flex-direction: row;
              justify-content: flex-start;
            }

            .creation-date {
              color: $lava25;
              margin: 0 10px;
            }
          }
          &-text {
            flex: 1;
            margin-top: 8px;
            background-color: $white;
            padding: 15px;
            box-shadow: $connected-card-shadow;
            max-width: 257px;
            word-wrap: break-word;
            border-radius: 15px;
          }
        }
      }
    }
    &-input {
      background-color: $white;
      width: 100%;
      padding: 10px;
      display: flex;
      .inner-input {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        width: 100%;
        background-color: $gray-placeholder;
        height: 35px;
        border: none;
        color: #3c3c4399;
        &:focus {
          outline: none;
        }
      }
      .send-button {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: $gray-placeholder;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
  }
}
