@import '../../styles/variables';

.report-fail {
  h5 {
    color: red;
    line-height: 18px;
    letter-spacing: 0.08em;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  h6 {
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.05em;
    span {
      cursor: pointer;
      color: $blue;
    }
  }
}
.report-success {
  display: flex;
  min-height: 400px;
  align-items: center;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
.report-loading {
  display: flex;
  min-height: 400px;
  align-items: center;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
.reports-setup {
  overflow-x: hidden;

  .reports-subtitle {
    font-family: Open Sans;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2.65rem;
    letter-spacing: 0.02em;
    width: 100%;
    margin: 0 auto;
    white-space: normal;
  }

  .turn-on-reports {
    /* Hide the default checkbox */
    input[type='checkbox'] {
      visibility: hidden;
    }

    /* Creating a custom checkbox
    based on demand */
    .geekmark {
      position: absolute;
      top: 0;
      left: -30px;
      height: 25px;
      width: 25px;
      background-color: white;
      border: 2px solid $sun;
      box-sizing: border-box;
      border-radius: 4px;
    }

    /* Checkmark to be shown in checkbox */
    /* It is not be shown when not checked */
    .geekmark:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  /* Specify the background color to be
        shown when checkbox is checked */
  .turn-on-reports input:checked ~ .geekmark {
    background-color: $sun;
  }
  /* Display checkmark when checked */
  .turn-on-reports input:checked ~ .geekmark:after {
    display: block;
  }

  /* Styling the checkmark using webkit */
  /* Rotated the rectangle by 45 degree and
      showing only two border to make it look
      like a tickmark */
  .turn-on-reports .geekmark:after {
    left: 8px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .reports-on-container {
    .date-picker-container {
      position: relative;
      /* Position the day picker in parent container with CSS scoping */
      .day-picker {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        width: auto;
      }
      /* arrow for positioned container src: https://stackoverflow.com/questions/9450733/css-triangle-custom-border-color */
      .day-picker:after,
      .day-picker:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 19px;
        border: 11px solid transparent;
      }
      .day-picker:after {
        left: 50%;
        transform: translate(-50%, 0);
        border: 10px solid transparent;
        border-bottom-color: $white;
      }
      .reports-delivery-display {
        .calendar-icon {
          position: relative;
          left: 8rem;
          top: 10px;
          z-index: 10;
        }
        input {
          border: 2px solid #bfbfbf;
          box-sizing: border-box;
          border-radius: 4px;
          text-align: left;
          width: 10rem;
          margin: 0 auto;
        }
      }
    }
    .date-picker-container::before {
      content: '';
      position: absolute;
      left: -140px;
      right: -140px;
      bottom: 0;
      height: 2px;
      border-bottom: 2px dashed rgba(139, 131, 152, 0.3);
      margin: 0 auto;
    }
    .date-picker-container::after {
      content: '';
      position: absolute;
      left: -140px;
      right: -140px;
      top: 0;
      height: 2px;
      margin: 0 auto;
      border-top: 2px dashed rgba(139, 131, 152, 0.3);
    }
  }

  .email-list-container {
    .email-icon {
      position: relative;
      top: 3px;
    }
    .email-error-message {
      color: red;
    }
    .add-email-container {
      display: flex;
      flex-direction: row;
      width: 60%;
      margin: 0 auto;

      input {
        border: 2px solid rgba(139, 131, 152, 0.3);
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 1.15rem;
        letter-spacing: 0.03em;
        flex-grow: 1;
        line-height: 22px;
        height: 5rem;
      }
      input:focus + button {
        background-color: $sun;
        color: $white;
        cursor: pointer;
        pointer-events: auto;
      }
      button {
        border: solid 2px $sun;
        text-transform: uppercase;
        height: 5rem;
        padding: 0 1rem;
        font-size: 1.15rem;
        width: 10rem;
        line-height: 1.5rem;
        font-weight: $bold-text;
        color: $sun;
        cursor: default;
      }
      .ready-to-add {
        background-color: $sun;
        color: $white;
        cursor: pointer;
      }
    }
    .recipient-email-list-container {
      outline: none;
      border: none;
      list-style: none;
      width: 60%;
      margin: 0 auto;

      li {
        background: #e6e6e6;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        height: 50px;

        h6 {
          flex-grow: 1;
          font-size: 1.2rem;
          line-height: 1.5rem;
          align-self: center;
          letter-spacing: 0.03em;
          text-align: left;
          width: 13rem;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        img {
          cursor: pointer;
          height: 2.5rem;
          align-self: center;
        }
      }
    }
    @media screen and (max-width: 400px) {
      .add-email-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;

        input {
          border: 2px solid rgba(139, 131, 152, 0.3);
          box-sizing: border-box;
          border-radius: 4px;
          font-size: 1.15rem;
          letter-spacing: 0.03em;
          flex-grow: 1;
          line-height: 22px;
          height: 5rem;
        }
      }
      .recipient-email-list-container {
        outline: none;
        border: none;
        list-style: none;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  .save-report {
    background: $sun;
    border: 2px solid $sun;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: uppercase;
    color: white;
    padding: 1.3rem;
    height: 3.5rem;
    width: 10rem;
    flex: 1;
    font-size: 1.15rem;
    font-weight: $bold-text;
    line-height: 31px;
    letter-spacing: 0.12em;
  }
  .disable-save {
    cursor: initial;
    opacity: 0.5;
  }
}
