$input-border-color: $gray-border2;
$input-hover-color: $gray-border;

input {
  border-width: 2px !important;
  padding: 1.5em !important;
}

.button.is-primary {
  padding: 1.8rem 3.25rem;
  font-weight: bold;
}

button.button {
  line-height: 0;
}
