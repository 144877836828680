@import '../../../../styles/variables';

.how-it-works-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .money-icon {
    width: 50px;
  }
  .sub-text {
    max-width: 700px;
    margin-top: 20px;
    @media (max-width: $mobile-breakpoint) {
      margin-bottom: 20px;
    }
  }
  .main-tittle {
    font-size: 34px;
    font-weight: bold;
    @media (max-width: $mobile-breakpoint) {
      margin-bottom: 50px;
    }
  }
  .info-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    .steps {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 0;
      .step-button {
        color: $lava;
        font-weight: 400;
        font-size: 15px;
        border: none;
        background-color: transparent;
        border-radius: 0;
        cursor: pointer;
        opacity: 0.5;
        margin: 0 25px;
        padding-bottom: 15px;
        &.active-tab {
          opacity: 1;
          font-weight: 700;
          border-bottom: 2px solid $ocean;
        }
      }
    }
    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .responsive-steps {
    display: none;
    @media (max-width: $mobile-breakpoint) {
      display: grid;
    }
  }

  .mkt-info-card {
    width: 913px;
    min-height: 324px;
    background-color: $white;
    padding: 37px;
    margin-top: 50px;
    margin-bottom: 9em;
    position: relative;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      margin-bottom: 6em;
    }
    .mkt-info-card-number {
      width: 40px;
      height: 40px;
      color: $white;
      background-color: $ocean;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      @media (max-width: $mobile-breakpoint) {
        margin-top: 6em;
      }
    }
    .mkt-info-card-tittle {
      font-size: 18px;
      font-weight: 700;
      margin-top: 15px;
      text-align: left;
      @media (max-width: $mobile-breakpoint) {
        width: 50%;
      }
    }
    .mkt-info-paragraph {
      margin-top: 8px;
      font-size: 14px;
      color: $lava75;
      text-align: justify;
    }
    .mkt-info-card-list {
      margin-top: 8px;
      li {
        font-size: 14px;
        color: $lava75;
        &.bold-mobile {
          @media (max-width: $mobile-breakpoint) {
            font-weight: bold;
          }
        }
        &::before {
          content: '\2022';
          color: $sun-bullet;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
    .mkt-info-card-text {
      width: 50%;
      text-align: left;
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
      }
    }
    .mkt-info-card-img {
      position: absolute;
      top: -4em;
      right: 4em;
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }
      width: 404px;
    }
    .mkt-info-card-img-mobile {
      display: none;
      position: absolute;
      top: -6em;
      right: 4em;
      width: 60%;
      @media (max-width: $mobile-breakpoint) {
        display: block;
        width: 45%;
      }
      @media (max-width: 520px) {
        display: block;
        width: 60%;
      }
    }
  }
}
