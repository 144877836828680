@import '../../../../../styles/variables';
.event-state {
  width: 100%;
  flex-direction: column;
  &-header {
    width: 100%;
    padding: 16px 0 18px;
    border-bottom: 0.7px solid $gray-separator;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    font-weight: bold;
    color: $lava;
    @media (max-width: $mobile-breakpoint) {
      font-size: 12px;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 13px;
      margin-bottom: 4px;
    }
  }

  .state-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .empty-state {
      color: $lava50;
      font-size: 14px;
      line-height: 19px;
      @media (max-width: $mobile-breakpoint) {
        font-size: 11px;
      }
    }
    .reserve-info {
      width: 50%;
      border-right: 0.7px solid $gray-separator;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .edit-label {
        color: $gray-subtitle;
      }
      .edit-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $gray-subtitle;
        img {
          margin-left: 5px;
        }
      }
      p {
        color: $lava75;
        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
      b {
        font-size: bold;
        font-size: 19px;
      }
      &.disabled {
        opacity: 0.5;
        .edit-button {
          cursor: default;
        }
      }
    }
    .opt-in-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .opt-in-label {
        color: $forest;
      }
      .opt-out-label {
        color: $canyon;
      }
      .opt-out-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $ocean;
        font-size: 12px;
      }
      p {
        font-size: 13px;
        color: $lava75;
      }
      b {
        font-size: 17px;
        color: $forest;
      }
    }
    .date-info {
      width: 100%;
      min-height: 116px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $lava;
      border-bottom: 0.7px solid $gray-separator;
      &-graph {
        display: none;
        margin: 5px 0 15px;
        @media (max-width: $mobile-breakpoint) {
          display: initial;
        }
        position: relative;
        .info-discharged {
          position: absolute;
          top: 24%;
          left: 50%;
          transform: translate(-50%, 0);
          width: 217px;
          padding-left: 100px;
          color: $gray-subtitle;
          b {
            font-size: 17px;
          }
        }
      }
      b {
        margin-top: 13px;
        font-size: 15px;
        font-weight: bold;
        @media (max-width: $mobile-breakpoint) {
          font-size: 14px;
        }
      }
      p {
        font-size: 13px;
        font-weight: 300;
        @media (max-width: $mobile-breakpoint) {
          font-size: 11px;
        }
      }
    }
    &.incoming-event {
      flex-direction: column;
      text-align: center;
      .row {
        width: 100%;
        min-height: 106px;
        display: flex;
      }
    }
    &.ongoing-event {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .disabled-image {
        margin-left: 5em;
        opacity: 0.5;
        @media (max-width: $mobile-breakpoint) {
          margin-top: -1em;
        }
      }
      .charged-info {
        border-left: 0.7px solid $gray-separator;
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 13px;

        &.right {
          @media (max-width: $mobile-breakpoint) {
            display: none;
          }
        }

        &.mobil {
          display: none;
          border: none;
          width: 100%;
          padding-top: 15px;
          @media (max-width: $mobile-breakpoint) {
            display: flex;
          }
          .info-discharged {
            top: 15%;
            b {
              font-size: 27px;
            }
          }
        }

        .info-discharged {
          white-space: nowrap;
          position: absolute;
          top: 23%;
          left: 50%;
          transform: translate(-50%, 0);
          width: 280px;
          padding-left: 150px;
          color: $lava;
          text-align: center;
          b {
            font-size: 27px;
          }
          &.disabled {
            color: $lava50;
            opacity: 0.5;
          }
        }
      }
      .info-wrapp {
        text-align: center;
        width: 50%;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }
        .row {
          display: flex;
          .reserve-info {
            padding: 14px 0;
          }
          .opt-in-info {
            padding: 14px 0;
          }
        }
      }
    }
  }
}
