.loader-wrapper {
  height: 100%;
  align-items: center;

  &.columns {
    display: flex;
  }

  img {
    height: 3em;
    width: 3em;
  }
}
