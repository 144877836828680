@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.cost-savings-mode-page {
  .cost-savings-mode-container {
    display: flex;
    img {
      height: 320px;
    }
  }
  button {
    border: solid 2px $sun;
    text-transform: uppercase;
    padding: 10px;
    height: 60px;
    width: 180px;
    font-weight: $bold-text;
    border-radius: $radius-large;
  }
  .cancel {
    background: $white;
    color: $sun;
  }
  .go-to-slider {
    background: $sun;
    color: $white;
  }
  .back {
    background: $sun;
    color: $white;
  }
  .utility-rate-plan-container {
    cursor: pointer;
    .utility-rate-plan-button {
      width: 325px;
      height: 62px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 25px;
      box-shadow: 4px 4px 27px rgba(114, 124, 128, 0.15);
      border-radius: 10px;
      .label-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        .info-label {
          color: $lava50;
          font-size: 10px;
        }
        .rate-plan-label {
          font-size: 14px;
          color: $lava75;
          font-weight: $bold-text;
        }
      }
      .urp-icon {
        width: 38px;
        margin-left: 50px;
      }
      .right-arrow-icon {
        width: 15px;
        margin-right: 15px;
      }
    }
  }

  .cost-savings-mode-slider {
    display: flex;
    flex-direction: column;
    text-align: center;

    .cost-savings-title {
      font-size: $font-size-bg;
      line-height: 31px;
      text-align: center;
      letter-spacing: 0.03em;
      color: $black;
    }
    .cost-savings-details-container {
      .cost-savings-value {
        font-size: 10em;
        text-align: center;
        letter-spacing: -0.07em;
        font-weight: 100;

        .percentage {
          font-size: 5rem;
          text-align: center;
          letter-spacing: -0.03em;
          color: $sun;
        }
      }
      h5 {
        font-size: 1rem;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1em;
        color: $gray-text-alt2;
      }
    }
    .slider-container {
      display: flex;
      align-items: center;
      width: 55%;
    }
  }
  .utility-rate-schedules {
    display: flex;
    img {
      height: 320px;
    }
    .utility-rate-description {
      max-width: 310px;
      h3 {
        font-size: 15px;
      }
    }
    .collapsible-container {
      max-width: 400px;
      box-shadow: 4px 4px 24px rgba(132, 161, 172, 0.22);
      border-radius: 10px;
      margin-bottom: 50px;
    }
    .collapsible-header {
      height: 57px;
      h3 {
        color: $lava75;
        font-weight: 700;
        font-size: 14px;
      }
      .question-chev {
        transform: rotate(0deg);
        transition-duration: 0.2s;
        width: 17px;
        height: 7px;
      }
    }
    .collapsible-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .radio-container {
        display: flex;
        input[type='radio'] {
          accent-color: $sun;
        }

        h3 {
          text-decoration: none;
          color: $lava75;
          font-weight: 700;
          font-size: 14px;
        }
      }
      .right-arrow-icon {
        width: 17px;
        height: 13px;
        cursor: pointer;
      }
    }
    .buttons-container {
      display: flex;
      button {
        height: 50px;
      }
      button:disabled,
      button[disabled] {
        border: 1px solid $disabled-sun;
        background-color: $disabled-sun;
        color: $white;
      }
      .cancel {
        border-color: $lava75;
        color: $lava75;
      }
    }
  }
  .main-main-container {
    position: relative;
  }
  .contact-us {
    position: absolute;
    bottom: -200px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    cursor: pointer;
    h3 {
      display: flex;
      font-weight: 400;
      font-size: 12px;
    }
    .anchor {
      margin-left: 5px;
      color: $sun;
    }
  }
}
@include touch {
  .cost-savings-mode-page {
    display: flex;
    flex-direction: column;
  }
  .cost-savings-mode-container {
    display: flex;
    flex-direction: column;
  }
  .utility-rate-schedules {
    display: flex;
    flex-direction: column;
  }
  .slider-container {
    width: 100% !important; // override the column width on mobile
  }
  .contact-us {
    height: 200px;
    bottom: 200px;
  }
}
