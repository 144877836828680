@import '../../styles/variables';

.panels-datepicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media all and (max-width: 420px) {
    width: 80%;
  }
  .disabled {
    opacity: 0.6;
    cursor: initial;
  }
  .switcher-label-toggle {
    display: grid;
    width: 15rem;
    grid-template-columns: 2rem 1fr 3rem;
    grid-template-rows: 2rem;
    gap: 0.5rem;
    align-items: center;
    justify-items: center;
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    .calendar-icon {
      border-radius: 30%;
      width: 2rem;
      height: 2rem;
      display: grid;

      img {
        align-self: center;
        justify-self: center;
        width: 1rem;
        height: 1rem;
      }

      &:hover {
        background-color: rgba(245, 245, 245, 0.4);
        border: solid 1px rgba(245, 245, 245, 0.8);
      }
      &.active {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .arrow {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;

    &:hover {
      background-color: rgba(245, 245, 245, 0.4);
      border: solid 1px rgba(245, 245, 245, 0.8);
    }
  }
  .panels-loader {
    margin-top: 8px;
    width: 15rem;
  }
  .filter-calendar {
    top: 110%;
    left: 40%;
    @media all and (max-width: 420px) {
      left: 20%;
    }
    width: 300px;
    .datepicker-nav {
      svg {
        stroke: $sun;
      }
    }
    .date-item.is-active {
      background-color: $sun !important;
      border-color: $sun !important;
    }
  }
}
