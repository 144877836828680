@import '../../../styles/variables';
.help-chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.help-chat-section {
  flex: 1;
  display: flex;
  justify-content: center;
}
.main-container {
  margin-top: 58px;
  max-width: 694px;
  flex: 1;
  display: flex;
}
.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-elements-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: $connected-card-shadow;
    max-height: 460px;
  }
}

.conversation-container {
  flex: 1;
  max-height: 460px;
  overflow-y: auto;
  scrollbar-gutter: var(--gutter, auto);

  .chat-ended-container {
    img {
      width: 80px;
      height: 80px;
    }
    .ended-chat-label {
      font-size: 14px;
      color: $lava75;
      margin-top: 11px;
    }
    button {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 10px;
      width: 134px;
      height: 46px;
      font-weight: bold;
      border-radius: 25px;
      background: $sun;
      color: $white;
      border: none;
      cursor: pointer;
    }
  }
}
.end-chat {
  width: 100%;
  button {
    background: none;
    border: none;
    padding: 0;
    font-weight: 700;
    color: $ocean;
    text-decoration: underline;
    cursor: pointer;
    padding: 12px 20px;
  }
}
.date-chat {
  width: 100%;
  font-size: 9px;
  color: $lava75;
  font-weight: 700;
}
.input-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  padding: 23px 24px 23px 21px;
  position: relative;
  box-shadow: $connected-card-shadow;
  input {
    background: white;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    border-color: $lava10;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 32px;
    border: 0;
    border-radius: 5px;
    color: white;
    padding: 8px 20px;
    position: absolute;
    right: 30px;
    top: 31px;
    background-color: $sun;
  }
  button:disabled {
    background-color: $gray-border2;
  }
}

.layout-cases-button {
  color: $sun;
  font-weight: bold;
  cursor: pointer;
}
