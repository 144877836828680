.flat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .range-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: black;
    .arrow-icon {
      margin-right: 13px;
      margin-left: 13px;
    }
  }
}

.legends-container {
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  justify-content: space-between;
  .single-legend {
    display: flex;
    flex-direction: row;
    .legend-square {
      height: 19px;
      width: 19px;
      border-radius: 3px;
      margin-right: 9px;
    }
  }
}
