@import "../../styles/variables";

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0e0e0e;
  opacity: 0.5;
  z-index: $z-index-backdrop;
  animation: fadeIn 0.5s both;
}
.menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25em;
  background-color: $white;
  z-index: $z-index-sidebar;
  animation: slide 0.35s forwards;
  .closeSideNav {
    position: relative;
    top: 0.1em;
    left: 85%;
    background-color: $white;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .menu-list {
    text-transform: uppercase;
    li {
      &:hover {
        .icon.on {
          display: block !important;
        }
        .icon.off {
          display: none !important;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      padding-left: 1em;
      .icon {
        margin: 0 0.7em 0.1em
      }
    }
    .selected {
      font-weight: $bold-text;
    }
  }
}

@keyframes slide {
  0% { right: -25em; }
  100% { right: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  25% { opacity: 0.1; }
  50% { opacity: 0.3; }
  75% { opacity: 0.4; }
  100% { opacity: 0.6; }
}
