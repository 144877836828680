@import '../../styles/variables';
.iframe-layout-wrapper {
  width: 100%;
  height: 73vh;
}
.connected-solutions {
  .program-details-container {
    margin: calc(4rem + 440px) auto 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    max-width: 1600px;

    &.bottom-section {
      margin-top: 20px;
    }

    section {
      margin-bottom: 7em;
      @media (max-width: $mobile-breakpoint) {
        margin-bottom: 1em;
      }
    }

    .storage-graph-asset {
      width: 50%;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .info-section {
      width: 50%;
      max-width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 30px;
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
        padding-left: 0;
      }
      &.desktop-animation {
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }
      }
      .desktop-text {
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }
      }
      .mobile-animation,
      .mobile-text {
        display: none;
        @media (max-width: $mobile-breakpoint) {
          display: block;
        }
      }
    }

    .section-subtittle {
      font-size: 34px;
      font-weight: 700;
      color: $lava;
      text-align: left;
      max-width: 335px;
      margin-left: 10px;
      @media (max-width: $mobile-breakpoint) {
        font-size: 26px;
        margin-left: 25px;
      }
    }
    .section-paragraph {
      text-align: left;
      margin-top: 13px;
      margin-left: 10px;
    }
    .section-info-list {
      text-align: left;
      margin-top: 13px;
      list-style: none;
      padding: 25px;
      @media (max-width: $mobile-breakpoint) {
        margin-top: 0;
      }
      li {
        margin-bottom: 7px;
        font-size: 15px;
        color: $lava75;
        &.bold-mobile {
          @media (max-width: $mobile-breakpoint) {
            font-weight: bold;
          }
        }
        &::before {
          content: '\2022';
          color: $sun-bullet;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}

.to-faq-button {
  background-color: transparent;
  border: solid 1px $sun;
  color: $white;
  cursor: pointer;
  padding: 6px 11px;
}

.iframe-wrapper {
  width: 100%;
  height: 100%;
}
