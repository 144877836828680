@import "../../styles/variables";
@import "~bulma/sass/utilities/_all.sass";

.mobile-monitoring-experience {
  position: relative;
  width: 100%;

  .background {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(180deg, #0071b9 0%, #6ec6e9 100%);
    color: $white;
    border-radius: 14px;
    width: 100%;
    height: 270px;
  }

  .experience-text {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: 18em;

    h2 {
      text-align: left;
    }
  }

  .app-buttons {
    margin-top: 2em;
  }

  .monitoring-experience-image-mobile {
    display: none;
  }

  .monitoring-experience-image {
    position: absolute;
    bottom: 0;
  }
}

@include desktop {
  .mobile-monitoring-experience {
    margin-top: 6em;
  }
}

@include touch {
  .mobile-monitoring-experience {
    .background {
      height: auto;
      flex-direction: column;
    }

    .experience-text {
      margin-top: 3em;
      margin-left: 1em;
      margin-right: 1em;
    }

    .monitoring-experience-image-mobile {
      display: initial;
    }

    .monitoring-experience-image {
      display: none;
    }
  }
}
