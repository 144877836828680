@import '../../styles/variables';

.api-error-contents {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: $font-size-bg;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $gray-text-alt2;
  }
  h6 {
    font-size: 0.625em;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1em;
    color: $gray-text-alt2;
  }
  button {
    border: solid 2px $sun;
    text-transform: uppercase;
    padding: 10px;
    height: 3.75em;
    width: 12.5em;
    font-weight: $bold-text;
    background: $sun;
    color: $white;
    border-radius: $radius-large;
  }
}
