@import '../../../../styles/variables';
.card {
  cursor: pointer;
  box-shadow: $connected-card-shadow;
  border-radius: 15px;
  margin-bottom: 15px;
  min-height: 112px;
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 2px;
  }
  .card-hiperlink {
    background: $connected-email-deg;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 13px;
  }

  &.no-padding {
    padding: 0;
    cursor: default;
  }
}

.settings-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    min-height: 50px;
  }
  .settings-icon {
    height: 24px;
    width: 24px;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
  .settings-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 767px) {
      flex: 1;
      justify-content: space-between;
    }
    .chevron-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      background-color: $accordion-accent;
      margin-left: 12px;
      .question-chev {
        transform: rotate(-90deg);
        margin-left: 1px;
      }
    }
  }
}

.card-row {
  display: flex;
  .card {
    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
@media (max-width: 767px) {
  .card-row {
    flex-direction: column;
    .card {
      margin: 0 0 13px;
    }
  }
}
