@import '../../styles/variables';

.feedback {
  h3 {
    font-weight: bold;
    font-size: 2.08rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-text {
    color: $gray-subtitle;
    font-size: 1rem;

  }

  &-content {
    padding: 1rem 5rem 8.5rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  &-buttons-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5rem;
  }

  &-button {
    text-transform: uppercase;
    border: none;
    margin-left: 1rem;
  }

  .check-img {
    margin: 0 .5rem;
  }

  &-message {
    font-weight: bold;
  }

  ::placeholder {
    font-style: italic;
  }
}
