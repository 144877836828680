@import '../../styles/variables';

.text-input-container {
  position: relative;
  margin: 0 auto;
  label {
    text-transform: uppercase;
    color: $gray-text-alt2;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70%;
    left: 0.85rem;
    position: relative;
  }
  input {
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 4px;
    color: $black;
  }
}
