@import '../../styles/variables';

.current-power {
  font-size: 1.1em;

  .now-producing {
    .level-left {
      color: $gray-text-alt2;
    }
    .level-right {
      font-weight: $bold-text;
    }
  }

  .disabled {
    background-color: $gray-background;
  }
}
