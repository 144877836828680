@import '../../styles/variables';

.profile-edit-modal {
  .user-details {
    display: flex;
    flex-direction: column;

    .text-input-container {
      width: 325px;
      margin-bottom: 2.5rem;
    }
  }
  .actions-container {
    position: relative;
    margin: 0 auto;
    width: fit-content;

    .error-message {
      color: red;
      text-align: center;
    }
    button {
      border: solid 2px $sun;
      padding: 10px;
      height: 50px;
      width: 155px;
      font-weight: $bold-text;
      border-radius: $radius-large;
    }
    .disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    .cancel {
      background: $white;
      color: $black;
    }
    .update {
      background: $sun;
      color: $white;
    }
  }
}
