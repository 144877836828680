@import '../../styles/variables';
@import "~bulma/sass/utilities/initial-variables";

.self-supply-mode-page {
  display: flex;
  justify-content: space-between;

  .self-supply-img {
    height: 185px;
  }
}
@media all and (max-width: $desktop)  {
  .self-supply-mode-page {
    display: flex;
    flex-direction: column;
  }
}
