@import '../../../styles/variables';

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  .title {
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    max-width: 530px;
  }
  .sub-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  }
  .buttons-container {
    margin-top: 60px;
    button {
      border: solid 2px $sun;
      text-transform: none;
      padding: 10px;
      height: 50px;
      width: 159px;
      font-weight: $bold-text;
      border-radius: $radius-large;
      cursor: pointer;
    }
    button:disabled {
      opacity: 0.3;
      cursor: no-drop;
    }
    .cancel {
      background: $white;
      color: $sun;
      margin-right: 5px;
    }
    .save {
      background: $sun;
      color: $white;
    }
  }
  .note {
    margin-top: 40px;
    max-width: 481px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $lava50;
  }
}

.slider-description {
  display: flex;
  flex-direction: row;

  .dollar-sign {
    color: $forest;
    margin-left: 5px;
  }
}
