@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.cost-savings-mode-page {
  .cost-savings-mode-container {
    display: flex;
    img {
      height: 320px;
    }
  }
  button {
    border: solid 2px $sun;
    text-transform: uppercase;
    padding: 10px;
    height: 60px;
    width: 180px;
    font-weight: $bold-text;
    border-radius: $radius-large;
  }
  .cancel {
    background: $white;
    color: $sun;
  }
  .go-to-slider {
    background: $sun;
    color: $white;
  }
  .back {
    background: $sun;
    color: $white;
  }
  .utility-rate-schedules {
    display: flex;
    img {
      height: 320px;
    }
    .utility-rate-description {
      max-width: 310px;
      h3 {
        font-size: 15px;
      }
    }
    .collapsible-container {
      max-width: 400px;
      box-shadow: 4px 4px 24px rgba(132, 161, 172, 0.22);
      border-radius: 10px;
      margin-bottom: 50px;
    }
    .collapsible-header {
      height: 57px;
      h3 {
        color: $lava75;
        font-weight: 700;
        font-size: 14px;
      }
      .question-chev {
        transform: rotate(0deg);
        transition-duration: 0.2s;
        width: 17px;
        height: 7px;
      }
    }
    .collapsible-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .radio-container {
        display: flex;
        input[type='radio'] {
          accent-color: $sun;
        }

        h3 {
          text-decoration: none;
          color: $lava75;
          font-weight: 700;
          font-size: 14px;
        }
      }
      .right-arrow-icon {
        width: 17px;
        height: 13px;
        cursor: pointer;
      }
    }
    .buttons-container {
      display: flex;
      button {
        height: 50px;
        cursor: pointer;
      }
      button:disabled,
      button[disabled] {
        border: 1px solid $disabled-sun;
        background-color: $disabled-sun;
        color: $white;
      }
      .cancel {
        border-color: $lava75;
        color: $lava75;
      }
    }
  }
}
@include touch {
  .cost-savings-mode-page {
    display: flex;
    flex-direction: column;
  }
  .cost-savings-mode-container {
    display: flex;
    flex-direction: column;
  }
  .utility-rate-schedules {
    display: flex;
    flex-direction: column;
  }
  .slider-container {
    width: 100% !important; // override the column width on mobile
  }
}
