@import '../../../../styles/variables';

.vpp-program-card {
  box-shadow: 2px 2px 8px rgba(200, 208, 211, 0.7);
  border-radius: 13px;
  width: 260px;
  margin: 15px 20px;
  display: flex;
  flex-direction: column;

  button {
    text-transform: none;
    font-weight: $bold-text;
    box-shadow: none;
    width: 120px;
  }

  .card-color-mark {
    height: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .card-program-name {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 15px 25px;
  }
  .card-program-row {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    margin: 5px 0px;
    font-size: 12px;
    color: $lava75;
    align-items: center;
    &.buttons-row {
      margin: 20px 0;
      margin-top: auto;
      padding-top: 20px;
    }

    .card-learn-more-button {
      color: $ocean;
      cursor: pointer;
    }
    .card-select-more-button {
      font-size: 14px;
      color: $lava;
      background-color: transparent;
      border: 1px solid $lava;
      border-radius: 24px;
      padding: 5px;
      cursor: pointer;
    }
  }
}
