@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 14px;
    color: $lava75;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    font-size: 17px;
    color: $lava75;
    letter-spacing: 0.1em;
  }

  .learn-more-button {
    min-width: 315px;
    margin-top: 40px;
  }

  .instructions {
    color: $lava50;
    font-size: 11px;
    max-width: 415px;
    text-align: center;
  }
}

.layout-styles {
  img {
    margin: -8.25rem auto 0 !important;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    margin: 72px 0 64px;
    .inner-link {
      text-align: center;
      font-size: 10px;
      text-transform: uppercase;
      color: $sun;
      font-weight: 600;
      letter-spacing: 0.12em;
      line-height: 14px;
      margin-bottom: 21px;
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}

@include mobile {
  .inner-container {
    p {
      font-size: 14px;
      text-align: center;
      margin: 0 15px;
    }
  }
}
