@import '../../styles/variables';
@import '~bulma/sass/utilities/initial-variables';

.details-container {
  margin-top: 67px;
  justify-content: center;

  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}
.mobile-seasons-list {
  width: 350px;
  margin-top: 35px;
}

.seasons-column {
  .seasons-code {
    font-weight: 700;
    font-size: 24px;
  }
  .seasons-cards {
    margin-top: 40px;
    .is-selected {
      border: 2px solid $sun;
    }
    .season-card {
      margin-top: 10px;
      cursor: pointer;
      .season-title {
        font-weight: 700;
        font-size: 14px;
      }
      .season-subtitle {
        color: $lava50;
        font-size: 11px;
      }
    }
  }
}
.pending-info-card {
  width: 350px;
  margin-top: 40px;
  .contact-button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-weight: bold;
    color: $sun;
  }
}

.seasons-button-container {
  margin-top: 25px;
  button {
    cursor: pointer;
    border: solid 1px $sun;
    text-transform: none;
    padding: 10px;
    height: 46px;
    width: 158px;
    border-radius: $radius-large;
  }
  .cancel {
    background: $white;
    color: $lava;
    border-color: $lava;
  }
  .go-to-slider {
    background: $sun;
    color: $white;
    font-weight: bold;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
