@import '../../styles/variables';
.login-layout {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .login-form {
    .login-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    display: flex;
    flex-direction: column;
    .login-input {
      font-size: 14px;
      border: 1px solid #ccc;
      width: 300px;
      padding: 6px !important;
      border-radius: 3px;
    }
    .login-button {
      border: none;
      background-color: $sun;
      color: white;
      width: 300px;
      margin-top: 15px;
      padding: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
