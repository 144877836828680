@import '../../styles/variables';

.faq-page {
  min-height: 600px;
  background: #ffffff;
  position: relative;
  top: 0px;
  z-index: 2;
  padding: 0;

  .tabs {
    padding: 1rem 0 3rem;
    li {
      margin: 0 20px;
    }
  }
  .documents-container {
    .card {
      box-shadow: none;
      .card-header {
        width: 100%;
        padding: 3em;
        border-top: 1px solid rgba(131, 139, 152, 0.1);

        .widget-icon {
          transform: rotate(-90deg);
        }
        .card-header-title {
          display: block;
          a {
            text-align: left;
            h3 {
              color: $blue;
              font-size: 14px;
              font-weight: 700;
              margin: 0;
            }
            span {
              color: black;
              font-size: 17px;
              font-weight: 400;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .message {
    margin: 0;

    .message-header {
      font-size: 1rem;
      font-weight: 300;
      font-family: 'Open Sans';
      background-color: white;
      flex-direction: row;
      flex-grow: 2;
      justify-content: space-between;
      cursor: pointer;
      text-decoration: none !important;
      border-radius: 0;
      border-top: 1px solid rgba(131, 139, 152, 0.1);
      padding: 1em;
      .question-chev {
        transform: rotate(0deg);
        transition-duration: 0.2s;
      }
      &.is-active {
        background-color: transparent;

        .question-chev {
          transform: rotate(180deg);
          transition-duration: 0.2s;
        }
      }
    }
    .message-body-content {
      background-color: white;
      padding: 1em 1em 4em 1.5em;
      font-size: 0.85rem;
      font-weight: 400;

      .color-text-blue {
        color: $blue !important;
      }
      article {
        margin: 2em 0;
      }
      ul {
        list-style: initial;
        margin-left: 2em;
      }
      ol {
        list-style-position: outside;
        width: 90%;
        margin-left: 2em;
        li {
          margin-left: 2.2em;

          ul {
            list-style: initial;
            list-style-position: outside;
            margin-left: 2em;

            li {
              padding-left: 1em;
            }
          }
        }
      }
    }
  }
}
.faq-house-img {
  position: relative;
  top: 10.83rem;
  margin: 0 auto;
  width: 400px;
  z-index: 3;
}
.faq-background {
  position: absolute;
  top: 50px;
  margin: 0 auto;
  width: 100%;
}
