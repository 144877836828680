$black: #191818;
$white: #ffffff;
$gray-border: #d3d7db;
$gray-border2: #e9ecee;
$gray-text-alt2: #838b98;
$gray-text: #717784;
$gray-subtitle: #808080;
$white-background: #fdfdfe;
$gray-background: #f4f6f8;
$gray-background2: #f5f5f5;
$gray-background3: #e3e7e9;
$gray-background4: #f8f9fa;
$gray-placeholder: #f4f6f9;
$gray-avatar: #d9d9d9;

$chat-gray-background: #f9fafb;

$light-gray: #fafafa;
$slider-background: #fbfbfb;
$sun: #f7921e;
$sun-bullet: #ef8b22;
$sun-avatar: #f3a859;
$disabled-sun: #f7dbbb;
$blue: #2b93cc;
$ocean: #0071b9;
$dark-blue: #4282c1;
$green: #7bb052;
$forest: #6aa442;
$red: #ef373e;
$citrus: #f26a36;
$canyon: #f26829;
$lava: #000000;
$lava10: #e6e6e6;
$lava25: #bfbfbf;
$lava75: #404040;
$lava50: #808080;
$off-peak: #d7d7d7;
$accordion-accent: #fce8d3;
$dark-green-overlay: rgba(6, 28, 37, 0.7);
$bold-text: 700;
$regular-text: 400;
$light-text: 300;
$link-color: #1f71b9;
$gray-separator: #bfbfbf;
$c-s-current-event1: #e2e9ed;
$c-s-current-event2: #dfe2e4;
$c-s-non-current-event2: #3e7518;

$z-index-backdrop: 5;
$z-index-sidebar: 10;
$z-index-max: 1000;

// Bulma overrides
$primary: $sun;
$secondary: $blue;

$navbar-height: 4rem;
$body-size: 12px;
$text: $black;

$menu-item-radius: 0;
$menu-list-line-height: 2.7rem;

$footer-background-color: $gray-background;
$footer-padding: 5rem 5rem 3rem;

$link: $gray-text !default;

$tabs-border-bottom-width: 0;

$radius-large: 6px;
$tooltip-radius: 14px;
$modal-content-width: 720px;
$modal-card-head-background-color: $white;
$modal-card-head-radius: 0;
$modal-card-foot-radius: 0;

$font-size: 1.15rem;
$font-size-bg: 1.5rem;
$on-peak-times-gradient: linear-gradient(180deg, #f26829 0%, #f7921e 100%);
$partial-peak-times-gradient: linear-gradient(180deg, #045886 0%, #2b93cc 100%);
$off-peak-times-gradient: linear-gradient(
  0deg,
  #bfbfbf -3.55%,
  #808080 102.26%
);
$critical-peak-times-gradient: linear-gradient(180deg, #6aa442 0%, #7ed321 0%);

// Bulma slider css overrides
$slider-radius: 30px;
$slider-thumb-radius: 30px;
$slider-track-radius: 30px;
$slider-track-background: $black;
$slider-thumb-background: $sun;
$slider-thumb-border: 1px solid $white;
$slider-thumb-shadow: 0px 0px 0px $gray-text-alt2;
$slider-track-border: 0px solid $white;
$slider-track-shadow: 0px 0px 0px $white;

$connected-header-active-deg: linear-gradient(180deg, $forest 0%, #568536 100%);
$connected-header-disabled-deg: linear-gradient(
  180deg,
  #d5dade 0%,
  #dee1e2 100%
);
$connected-card-shadow: 4px 4px 24px rgba(143, 161, 172, 0.3);
$connected-email-deg: linear-gradient(98.51deg, #0488ce 20.57%, #074b70 99.74%);
$connected-help-text: #1a1919;

$mobile-breakpoint: 767px;
$support-card-border: #f4f4f4;
