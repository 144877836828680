@import "../../styles/variables";

.signal-indicator {
  height: 20px;

  .indicator {
    display: inline-block;
    margin-right: 0.3em;
    content: '';
    width: 5px;
    background-color: $gray-text-alt2;

    &.signal-1 {
      height: 20%;
    }
    &.signal-2 {
      height: 40%;
    }
    &.signal-3 {
      height: 60%;
    }
    &.signal-4 {
      height: 80%;
    }
    &.signal-5 {
      height: 100%;
    }

    &.active {
      background-color: $green;
    }
  }
}
