.switch-header {
  display: flex;
  justify-content: space-between;
  padding: 7px 20px;
  width: 100%;
  background: #ef8b22;
  z-index: 3;
  .inner-text {
    text-decoration: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    cursor: pointer;
  }
  .target {
    font-weight: bold;
  }
}
