@import '../../styles/variables';
@import '~bulma/sass/utilities/initial-variables';

.reserve-setup-page {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.switch-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.02em;
}

.bubble-chat {
  width: 251px;
  height: 50px;
  background: #f6f6f6;
  position: relative;
  margin-top: 16px;
  color: #808080;
  padding: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
}
.bubble-chat:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 45%;
  width: 0;
  border-bottom: 11px solid #f6f6f6;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}
.infinite-svg {
  margin-bottom: 18px;
}
.steps-container {
  &.hidden {
    visibility: hidden;
  }
}
