@import '../../../styles/variables';
@import "~bulma/sass/utilities/initial-variables";

@media (max-width: $tablet) {
  .referral-container .section {
    padding: 3rem;
  }
}

.referral {
  &-container {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
    align-items: center;
  }

  &-title {
    font-size: 1.75rem;
  }

  &-btn {
    background-color: $sun;
    color: $white;
  }

  &-text {
    color: $lava25;
    font-size: 1.2rem;
    b {
      color: $black;
    }
  }

  &-image-container {
    align-self: center;
  }

  &-info-column {
    height: 24rem;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 2rem;
    margin: 0 auto;
    width: 30rem;
  }

  &-row-reverse {
    flex-direction: row-reverse;
  }
}
