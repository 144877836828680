@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import './styles/variables';
@import './styles/form';
@import 'bulma';
@import '~bulma-divider/src/sass/index.sass';
@import '~bulma-calendar/src/sass/index.sass';
// @import '~bulma-slider/src/sass/index.sass';
@import '~@creativebulma/bulma-collapsible';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// SOURCE:  https://stackoverflow.com/questions/25610517/ios-devices-issues-with-html-form-input-type-text
input,
textarea {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
@font-face {
  font-family: 'icons-regular';
  src: local('icons-regular'), url(./styles/icons-regular.woff) format('woff');
}

html {
  height: 100%;
}

html,
body {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(360deg, #f8f9fa 1.81%, #ffffff 97.68%);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.full-min-height {
  min-height: 100vh;
}

.is-size-5-5 {
  font-size: $font-size;
}

.has-bar {
  padding-top: $navbar-height + 1rem;
}

.navbar {
  position: fixed;
  padding-left: 20px;
  padding-right: 20px;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-item,
.navbar-link {
  padding: 0;
}

.navbar-brand {
  align-items: center;
}

.is-flex-centered {
  justify-content: center;
}

.light-text {
  font-weight: $light-text;
}

.is-size-1 {
  line-height: 1.2;
}
