@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';

.storage {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  display: grid;
  gap: 2em;
  grid-template-areas: 'storage-summary-area battery-icon-area storage-details-area';
  a {
    color: $sun;
  }

  .storage-label {
    color: $gray-text-alt2;
    grid-area: storage-summary-area;
  }
  .column-override {
    flex-grow: none; // flex grow with text-align left was causing text to go too far
  }
  .battery-summary-container {
    width: 70%;
    margin: 0 auto;
    color: $black;

    h6 {
      font-size: 0.9375em;
      line-height: 12px;
      letter-spacing: 0.25em;
      color: $gray-text-alt2;
    }
    .edit-operational-mode-steps {
      font-size: 0.9375em;
      line-height: 12px;
      letter-spacing: 0.25em;
      color: $sun;
      cursor: pointer;
      font-weight: $bold-text;
    }
  }
  .battery-wrapper {
    * {
      letter-spacing: 0.25em;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .battery-icon {
    grid-area: battery-icon-area;
    align-self: center;
    justify-self: center;
  }
  .summary-wrapper {
    display: flex;
    flex-direction: row;
    border-left: 1px solid $gray-background;
    padding-left: 10em;
  }
  .storage-system-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 7em;
    min-height: 20em;
    margin-bottom: 7em;
  }
  .operational-mode-details {
    grid-area: storage-details-area;
    .reserve-mode-container {
      color: $gray-text-alt2;
      .time-remaining-container {
        width: 60%;
      }
      h4 {
        font-size: 0.9375em;
        line-height: 13px;
        letter-spacing: 0.15em;
        margin-left: 0.75rem;
      }
      h6 {
        font-size: 0.9375em;
        line-height: 13px;
        letter-spacing: 0.15em;
        text-align: center;
      }
      h5 {
        font-size: 4.5em;
        line-height: 54px;
        text-align: center;
        letter-spacing: 0.1em;
      }
      .divider:after {
        content: '';
        position: relative;
        top: 15px;
        left: 12px;
        border-right: dashed 1px #8fa1ac;
      }
    }
    .charging-mode-container {
      .reserve-level {
        font-size: 5.3125em;
        line-height: 54px;
        letter-spacing: 0.03em;
        color: $gray-text-alt2;
        .percentage {
          font-size: 2.0625rem;
          line-height: 54px;
          text-align: center;
          color: $gray-text-alt2;
        }
      }
      p {
        margin: 0;
        text-transform: none !important; // bluma override
      }
    }
  }

  .event-wrapper {
    padding-left: 36px;
  }
  .event-tittle {
    font-size: 16px;
    font-weight: 700;
    color: $lava;
    margin-bottom: 7px;
  }
  .event-subtittle {
    margin-top: 12px;
  }
  .event-date-info {
    color: $lava;
    font-weight: 600;
    font-size: 10px;
  }
  .event-opt-in-state {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    color: $forest;
    font-weight: 700;
    font-size: 10px;
    border-top: 1px solid $lava10;
    border-bottom: 1px solid $lava10;
    margin-top: 22px;
    margin-bottom: 16px;
    .opt-out-button {
      background-color: transparent;
      border: none;
      color: $blue;
      text-align: right;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .event-saved-reserves {
    font-size: 40px;
    font-weight: 700;
    color: $lava;
    display: flex;
    align-items: flex-end;
    &-simbol {
      font-size: 20px;
      font-weight: 400;
      line-height: 50px;
    }
  }
  .event-edit-container {
    display: flex;
    font-size: 12px;
    align-items: center;
    .event-edit-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
.infinite-mode-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375em;
    line-height: 13px;
    letter-spacing: 0.15em;
    color: #838b98;
  }
  .infinite-container {
    background-color: #f3f6f6;
    padding: 8px 8px 9px 10px;
    color: #9198a4;
    border-radius: 5px;
    display: flex;
    margin-top: 5px;
    .infinite-label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 0.9375em;
      line-height: 16px;
      margin-left: 6px;
    }
  }
}

@include touch {
  .storage {
    display: grid;
    grid-template-areas:
      'battery-icon-area battery-icon-area'
      'storage-summary-area storage-details-area';

    .battery-summary-container {
      width: 100%;
      margin: 0 auto;
    }
    .reserve-mode-container {
      h4 {
        margin: 0 !important; //h4 tag override
      }
      .time-remaining-container {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
