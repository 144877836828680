@import '../../styles/variables';

.battery-operational-mode-edit-contents {
  border-radius: $radius-large;
  display: flex;
  flex-direction: column;
  .options-container {
    display: flex;

    .container {
      background: $white;
      box-shadow: 4px 4px 24px rgba(132, 161, 172, 0.22);
      border-radius: 13px;
      text-align: center;
      height: 220px;
      cursor: pointer;

      img {
        height: 2em;
      }
      h4 {
        font-weight: $light-text;
        font-size: $font-size-bg;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: $gray-text-alt2;
      }
      p {
        font-size: 1em;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        color: $black;
        padding: 1.5em;
      }
    }
  }
  .reserve-mode {
    text-align: center;

    h5 {
      font-size: 0.9375em;
      line-height: 1.25em;
      text-align: center;
      letter-spacing: 0.1em;
      color: $gray-text-alt2;
    }
    .reserve-mode-command {
      text-transform: uppercase;
      font-weight: $bold-text;
      font-size: 0.75em;
      line-height: 1.125em;
      text-align: center;
      letter-spacing: 0.1em;
      color: $dark-blue;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 768px) {
    .options-container {
      display: flex;
      flex-direction: column;
      .battery-mode {
        width: 100%;
      }
    }
  }
}
