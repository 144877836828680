@import '../../styles/variables';

.system-connection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .description {
    color: $gray-text;
    margin-bottom: 2em;
    letter-spacing: 2px;
  }

  .green-text {
    color: $green;
  }

  .red-text {
    color: $red;
  }

  .status,
  .head {
    display: flex;
    justify-content: center;
  }

  &.disconnected {
    img {
      height: 3.5 em;
    }

    h2.green-text {
      color: $black;
    }
  }

  &.connection-error {
    img {
      margin-left: 2em;
      margin-right: 2em;
      width: 100%;
    }

    h2.green-text {
      color: $black;
    }
  }

  &.ethernet {
    img {
      height: 6em;
    }
  }

  &.power-line {
    img {
      height: 6em;
    }
  }

  &.cellular {
    img {
      height: 3.5em;
    }
  }

  &.icd-alert {
    .status {
      flex-direction: column;
      h2.green-text {
        color: $canyon;
        padding: 0 10%;
        font-size: 1.7rem !important;
      }
      p {
        text-align: center;
        padding: 0 20%;
        color: $gray-subtitle;
        letter-spacing: 0.1em;
        a {
          color: $blue;
        }
      }
    }
  }
}
