@import '../../styles/variables';
.container {
  // this is to force the background sky image to stretch when using left: 0 right: 0
  position: initial;
}

.container-helper {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  @media (min-width: 750px) {
    align-items: center;
  }
}
.header-clouds-container {
  position: relative;
  width: 100vw;
  margin: -10px -10px 0px -10px;
  .clouds-container {
    width: 100%;
    height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}
.header-circle {
  top: 140px;
  height: 210px;
  border-radius: 100%;
  position: absolute;
  background: $white-background;
  right: -200px;
  left: -200px;
}
.panels-page {
  max-width: 950px;
  box-shadow: none;
  .info-icon {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: 5%;
    cursor: pointer;
    @media all and (max-width: '375px') {
      right: 10%;
    }
  }
  .panels-header {
    position: relative;
    right: 0;
    top: 25px;
    left: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;

    .panel-toolbar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-bottom: 20px;
      width: 100%;
      position: relative;
      .toggle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .toggle-label {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 23px;
          letter-spacing: 0.03em;
          color: $white;
          margin: 0px 7px;
        }
        .toggle-label-selected {
          font-weight: bold;
        }
      }
    }
  }
  .display-on-load {
    display: flex;
  }
  .panels-list {
    position: relative;
    display: grid;
    grid-template-areas:
      'list-summary-area list-count-area'
      'panels-list-area panels-list-area';
    background-color: transparent;
    padding: 1rem;
    .summary-value-total {
      grid-area: list-summary-area;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-self: left;
      padding: 1rem;
      font-size: 1.55rem;
      h4 {
        margin-right: 1rem;
        font-weight: bold;
      }
      span {
        color: $gray-text-alt2;
      }
    }
    .panel-count {
      grid-area: list-count-area;
      white-space: nowrap;
      display: flex;
      align-self: center;
      justify-self: right;
      padding: 1rem;
      font-size: 1.5rem;
      color: $gray-text-alt2;
      @media all and (max-width: '375px') {
        display: none;
      }
    }
    .list-container {
      grid-area: panels-list-area;
      background-color: $white;
      border-radius: 2rem 2rem 0 0;
      box-shadow: 5.41743px 5.41743px 35.2133px #d9e0e2;

      .panel-list-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        border: 1px solid #eeecec;
        border-radius: 2rem 2rem 0 0;
        h3 {
          font-weight: bold;
        }
      }
      .panel-list-item-container {
        cursor: pointer;
        padding: 1rem;
        display: grid;
        grid-template-columns: 2rem 1fr 2rem 1rem;
        grid-template-rows: 2rem;
        gap: 2rem;
        align-items: center;
        border: 1px solid #f3f3f3;

        &:hover {
          background-color: #f8f8f8;
        }
        .status {
          width: 50%;
          height: 50%;
          border-radius: 50%;
        }
      }
    }
  }
}

.slider {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 396px;
  z-index: 21;
  animation: slide 0.35s forwards;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .toolbar {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      .closeSideNav {
        background-color: $white;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
    .info-container {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-family: Open Sans;
      font-style: normal;
      .date-label {
        display: flex;
        justify-content: center;
        font-size: 1rem;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.15em;
        margin-top: 5px;
        .day-week-label {
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 5px;
        }
      }
      .status-container {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
        padding: 10px 16px 10px 16px;
        justify-content: space-between;

        h4 {
          margin-left: 4px;
        }
        .status-icon {
          width: 20px;
          height: 20px;
        }
      }
      .messaging-container {
        display: flex;
        margin: 10px 22px 16px 17px;
        justify-content: flex-start;
        flex-direction: column;
        font-weight: normal;
        font-size: 1.083rem;
        line-height: 21px;
        letter-spacing: 0.09em;
        .title-messaging {
          font-weight: bold;
        }
        .sub-title {
          color: $lava50;
          text-transform: uppercase;
          font-size: 1rem;
        }
        .content {
          margin-top: 24px;
        }
        .support-email {
          background: none;
          border: none;
          padding: 0;
          color: $link-color;
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 5px;
        }
      }
      .separator {
        height: 1px;
        background-color: $gray-separator;
        margin: 0px 22px 0px 17px;
        opacity: 0.4;
      }
      .last-reporter-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px;
        .lastly-label {
          color: $lava50;
        }
      }
      .graph-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .graph-options-container {
          display: flex;
          justify-content: center;
          font-size: 1.083rem;
          line-height: 21px;
          margin-top: 8px;
          .graph-option {
            font-weight: normal;
            margin-right: 10px;
            cursor: pointer;
          }
          .graph-option-selected {
            font-weight: bold;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .cards-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 43px;
        .card {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 153px;
          height: 134px;
          border-radius: 14px;
          margin-right: 18px;
          .calendar-illustration {
            margin-bottom: 15px;
          }
          .chart-illustration {
            margin-bottom: 23px;
          }
          .day-label {
            font-size: 0.917rem;
            line-height: 20px;
          }
          .amount-label {
            font-size: 1.333rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.content-with-slider {
  margin-right: 396px;
  .info-icon {
    right: 30%;
  }
}

.tooltip {
  width: 150px;
  padding: 10px;
  background: $white;
  box-shadow: 4px 4px 16px $gray-border2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  .energy {
    font-size: 2rem;
  }
}
