@import '~bulma/sass/utilities/_all.sass';
@import '../../styles/variables';

.storage-modal-content {
  .center-content {
    text-align: center;

    .empty-battery-icon {
      height: 150px;

      @media (max-width: $mobile-breakpoint) {
        height: 100px;
      }
    }

    .depleted-battery-text {
      line-height: 25px;
      font-size: 18px;

      @media (max-width: $mobile-breakpoint) {
        line-height: 20px;
        font-size: 15px;
    }
    }
  }
}
