@import '../../styles/variables';

.savings {
  display: flex;
  flex-direction: column;

  .savings-tabs {
    margin-bottom: 0;
  }

  .bill-savings-tab {
    > div:first-child {
      margin-top: 4.5em;
      margin-bottom: 4.5em;
    }
  }
  .environmental-tab {
    > div:first-child {
      margin-top: 4.5em;
      margin-bottom: 4em;
    }
  }

  ul {
    justify-content: space-between !important;
    overflow: hidden;
  }
}

.bill-savings {
  display: flex;
  justify-content: center;

  .status-icon {
    svg {
      margin-top: 1em;
    }
  }
}

.environmental-savings-detail {
  justify-content: left;

  .status-label-wrapper {
    margin-left: 2.5em;
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .level-right {
    flex-grow: 0 !important;
    margin-left: 2.5em;
    width: 3.5em;
  }
}
