@import '../../styles/variables';
@import '~bulma/sass/utilities/initial-variables';

.bg-box {
  max-width: 892px;
  margin: 0 auto;

  .house-img {
    margin: -14.25rem auto 0;
    @media all and (max-width: $tablet) {
      margin-top: -118px;
      width: 300px;
    }
  }

  &-background {
    background: url('./assets/sky-background.svg') no-repeat;
    background-position: right 0 top;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 300px;
    left: 0;
    top: -5px;
  }
  &-invert-background {
    background: url('./assets/sky-1024.svg') no-repeat;
    position: fixed;
    background-size: cover;
    width: 100%;
    height: 360px;
    left: 0;
    top: -5px;
    @media all and (max-width: '1024px') {
      background: url('./assets/sky-768.svg') no-repeat;
    }

    @media all and (max-width: '375px') {
      background: url('./assets/sky-375.svg') no-repeat;
    }
  }

  .is-divider[data-content],
  .is-divider-vertical[data-content] {
    &::after {
      border-radius: 100%;
      background-color: #f3f5f6;
      padding: 1rem;
      margin-top: -0.5rem;
    }
  }

  &-content {
    position: relative;
    margin-top: 15rem;
    min-width: 25rem;
    display: flex;
    flex-direction: column;
  }
}
.dashboard-loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30vh;
}
