@import '../../styles/variables';

.energy-mix {
  font-size: 1.1em;

  .level-left {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 75%;
      text-align: left;
    }
  }
  .level-right {
    font-weight: $bold-text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .summary {
    font-weight: $bold-text;
    padding-bottom: 1.5rem;
    border-bottom: 1px dashed $gray-border;
  }
  .summary-item {
    font-weight: $bold-text;
  }
}
