@import '../../styles/variables';
@import '~bulma/sass/utilities/_all.sass';
.navbar,
.navbar-panels {
  position: fixed;
  width: 100%;
  background-color: $white-background;
  z-index: 20;
  height: 4rem;
  align-items: center;
  box-shadow: 2px 2px 8px rgba(200, 208, 211, 0.7);
  .profile-icon {
    cursor: pointer;
  }
  .header-tabs-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 6rem;
    li {
      width: fit-content;
      list-style-type: none;
      &.selected {
        font-weight: bold;
      }
    }
  }
  .site-selection-area {
    display: flex;
    position: absolute;
    right: 9rem;
    padding: 0 1rem 0 1rem;
    height: 4rem;
    align-items: center;
    border-left: solid 1px rgba(200, 208, 211, 0.7);
    border-right: solid 1px rgba(200, 208, 211, 0.7);
  }
  .navbar-brand {
    position: absolute;
    left: 0;
    margin-left: 20px;
  }
  .nav-var-right-container {
    display: flex;
    position: absolute;
    padding-right: 15px;
    right: 0px;
    &.has-menu {
      border-right: 2px solid $lava10;
      right: 50px;
    }
  }
  .navbar-end {
    position: absolute;
    right: 0;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    img {
      margin-left: 20px;
    }
    h6 {
      margin-left: 10px;
    }
  }
}
.logo {
  margin-top: 0.5em;
  width: 155px;
  height: 25px;
  img {
    max-height: initial;
  }
}
.navbar-title {
  h1 {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    cursor: pointer;
    &.hide-cursor {
      cursor: auto;
    }
  }
}
@include touch {
  .navbar {
    .navbar-brand {
      position: absolute;
      left: 0;
      margin-left: 5px;
    }
    .navbar-end {
      position: absolute;
      right: 0;
      margin-right: 5px;
    }
    padding-left: 160px;
    padding-right: 26px;
    &.hiddenLogo {
      padding: 0;
    }
  }
  .navbar-panels {
    height: fit-content;
    width: 100%;
    .header-items-container {
      height: 100%;
      display: grid;
      grid-template-areas:
        'brand-logo-area . menu-icon-area'
        'site-select-area site-select-area site-select-area';
      grid-template-columns: 15rem 1fr 10rem;
      .navbar-brand {
        grid-area: brand-logo-area;
        margin-left: 5px;
        position: initial;
      }
      .navbar-end {
        position: initial;
        grid-area: menu-icon-area;
        display: flex;
        height: 4rem;
        flex-direction: row;
        border-left: solid 1px rgba(200, 208, 211, 0.7);
        align-items: center;
        margin-right: 20px;
        margin-left: 20px;
        img {
          margin-left: 20px;
        }
        h6 {
          margin-left: 10px;
        }
      }
      .header-tabs-container {
        display: none;
      }
      .site-selection-area {
        position: initial;
        grid-area: site-select-area;
        width: 100%;
        padding: 1rem;
        grid-column: 1 / 4;
        border: solid 1px rgba(200, 208, 211, 0.7);
        justify-content: center;
      }
    }
  }
}
